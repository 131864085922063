import React from "react";
import "./HalalPage.styles.css";
import { organisations } from "./organisations";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

export default function HalalPage() {
  return (
    <>
      <SectionHeader>
        <h1 className="title"> ハラールについて</h1>
      </SectionHeader>
      <section className="halal-page-header">
        <div className="container-full">
          <div className="row align-center">
            <div className="col-md-6 mobile-order-2">
              <div className="text-wrapper">
                <h2 className="heading accent-color b-font">
                  ジャパンダアワセンター(JDC)のハラールポリシー
                </h2>
                <h4 className="pre-heading">
                  ジャパンダアワセンター（JDC）は、最高品質のサービスを提供できるコンサルタントとして運営に励んでいます。日本のハラール製品メーカーへの効果的なハラールの証明書の所得に向けて、以下のお手伝いをさせていただいております。
                </h4>
                <ul>
                  <li>
                    ハラールとそれに伴う必要な知識の提供と、イスラーム諸国への効果的な販売戦略の考案
                  </li>
                  <li>
                    ハラールの基準と要件に従うハラール証明発行会社に繋ぎ、証明発行までの管理
                  </li>
                  <li>
                    妥当性監査を実施し、ニーズに合ったハラール証明発行会社の紹介
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="./images/halal-page/man-holding-quran.jpg"
                alt="Man Holding Quran"
              />
            </div>
          </div>
          <div className="row align-center">
            <div className="col-md-6">
              <img src="./images/halal-page/quran.jpg" alt="Halal Dates" />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h3 className="pre-heading">
                  ハラールとはアラビア語で「許可された」という意味です。ハラールは一般的に、クルアーンで見られるイスラム教のガイドラインの下で許可されている食品を意味します。ほとんどの飲食物はハラールですが、一部はクルアーン（啓典）とハディース（預言者ムハンマドs.a.wの言行録）に明確に禁止事項として書かれています。
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="haram-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="pre-heading">
                ハラームに該当する代表的な飲食物は豚肉とアルコールです。
              </h3>
              <h3 className="pre-heading">
                特に豚肉に関しては厳しく禁じられていて、豚肉そのものだけではなく、豚由来の成分が含まれているものや、豚に触れた食品や調理器具なども全面的に禁忌となっています。
              </h3>
              <h3 className="pre-heading">
                例えば、豚由来のゼラチンやショートニング、ラードなとがあります。
              </h3>
              <h3 className="pre-heading">
                牛肉や鶏肉などは食べることができますが、イスラーム法に則った食肉処理が施されている必要があります。もしこの処理が行われていない場合、食べることができません。
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="halal-haram">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h4 className="pre-heading mb-30">
                クルアーンでは、ハラールの対義語にハラームがあります。
                この二項対立は、「5つの決定」として知られるより複雑な分類に精緻化されました。
              </h4>
              <h2 className="heading ">
                <span className="g">必須、</span>
                <span className="b"> 推奨、</span>
                <span className="n">中立的、</span>
                <span className="o">非推奨、</span>
                <span className="r">禁止</span>
              </h2>
              <h4 className="pre-heading mt-30 mb-50">
                イスラーム法学者は、ハラールという用語がこのカテゴリーの最初の2つまたは最初の4つをカバーするかどうかについては意見が分かれています
              </h4>
              <img src="./images/halal-page/halal-haram.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="halal-toyyiban light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="text-wrapper">
                <h3 className="pre-heading mb-30">ハララン・タイバンとは</h3>
                <p className="mb-30">
                  <strong>
                    {" "}
                    タイイバンは、良質、安全、清潔、栄養価高さ、本物であることを表します。
                  </strong>
                </p>
                <p>
                  ハララン・タイバンの原理は、クルアーン(雌牛章172節)の単語から派生した概念です。
                  この文は、ムスリムの消費者に、良質かつ健康な食品を選び、不十分なハラールの基準を作らないようにすることが書かれています。
                </p>
              </div>
              <img src="./images/halal-page/halal-cert.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="ayah">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6">
              <img src="./images/halal-page/quran-2.jpg" alt="" />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h3 className="pre-heading">
                  信仰する者よ、われがあなたがたに与えた良いものを食べなさい。そしてアッラーに感謝しなさい。もしあなたがたが本当に、かれに仕えるのであるならば。
                  <br />
                  <br /> <strong>(クルアーン2：172)</strong>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="halal-cert light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-50">
              <h2 className="heading">
                ハラール認証は、消費者が求める製品のハラールの確実性を担保するための方法です。
              </h2>
              <h2 className="heading">商品を買うとき</h2>
            </div>
            <div className="col-md-12 text-center">
              <h3 className="pre-heading">
                食品において、ハラールとハラームが外観から判断できるものもあれば、調味料や加工食品など、外側からだけでは、判断できないものも多くあります。特にイスラームが主流ではない日本において、ムスリムはこのような食品を気軽に手に取ることができません。ここでハラール認証が商品購入の決め手に繋がるのです。
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="halal-product">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6">
              <img src="./images/halal-page/bottle.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h2 className="heading">例:</h2>
                <h3 className="pre-heading mb-30"> 製品A:</h3>
                <ul>
                  <li>2番目に気に入った製品</li>
                  <li>ハラールロゴを取得</li>
                  <li>ハラール</li>
                </ul>
                <p>
                  <strong>ムスリムが購入</strong>
                </p>
                <hr />
                <h3 className="pre-heading mb-30 mt-20"> 製品B:</h3>
                <ul>
                  <li>最も気に入った製品</li>
                  <li>ハラール</li>
                  <li>ハラールロゴなし</li>
                </ul>
                <p>
                  <strong>ムスリムは購入しない</strong>
                </p>
                <p>(ハラールの確実性ない)</p>
                <p> ハラールではないと言う必要はありません</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">
                世界的な健康食ブームや和食ブームの中、ムスリム達は日本食に含まれる様々なハラームの材料を心配します。ハラール認証された日本の食材や料理を提供することで、国内外のムスリムに向けた需要が高まることが期待されます。
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div style={{ marginBottom: "50px" }} className="col-md-12">
              <h2 className="pre-heading">公認を受けているハラール認証機関</h2>
            </div>
            {organisations.map((org) => (
              <div className="col-md-6 row" key={org.id}>
                <div className="f-contact">
                  <img src={org.image} alt={org.name} />
                  <h3 className="org-name">{org.name}</h3>
                  <div className="f-address">
                    <h4>Address:</h4>
                    <address>
                      {org.addressLine1} <br />
                      {org.addressLine2} <br />
                      {org.addressLine3} <br />
                      {org.country}
                    </address>
                  </div>
                  <div className="f-des">
                    <h4>Contact:</h4>
                    <h5>{org.orgHead}</h5>
                    <p>{org.orgDes}</p>
                    <p>Tel: {org.tel}</p>
                    {org.fax && <p>Fax: {org.fax}</p>}
                    <p>
                      <a href={"mailto:" + org.email}>{org.email}</a>
                    </p>
                    {org.email2 && (
                      <p>
                        <a href={"mailto:" + org.email2}>{org.email2}</a>
                      </p>
                    )}
                    {org.website && (
                      <p>
                        <a
                          href={org.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {org.website}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
