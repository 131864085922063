import React from "react";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <>
      <section className="not-found">
        <div>
          <h1>404 Page not found</h1>
          <Link to="/">Return to Homepage</Link>
        </div>
      </section>
    </>
  );
}
