export const organisations = [
    {
        id: 1,
        name: 'Japan Muslim Association',
        addressLine1: '3-4-14 Kohinata' ,
        addressLine2: 'Bunkyo-ku',
        addressLine3: 'Tokyo 112-8585',
        country: 'Japan',
        orgHead: 'Prof. Hideomi (Tayeb Muto)',
        orgDes: '(Chairman)',
        tel: '+81 33947 2419',
        fax: '+81 33947 9416',
        email: 'jma@ac.auone.net.jp',
        email2: 'info@shariah-inst.com',
        image: '/images/jma.PNG'
    },
    {
        id: 2,
        name: 'Japan Halal Association (JHA)',
        addressLine1: '547-0035 Osaka Shi Hirano-Ku' ,
        addressLine2: 'Nishiwaki 1-1-2',
        addressLine3: 'Miyako San-Ai Building',
        country: 'Japan',
        orgHead: 'Mdm Hind Hitomi Remon',
        orgDes: '(Chairman)',
        tel: '+81 6 6704 7080',
        fax: '+81 6 6704 9505',
        email: 'info@jhalal.com',
        email2: 'remon@jhalal.com',
        image: '/images/jha.PNG'
    },
    {
        id: 3,
        name: 'Japan Halal Unit Association (JHUA)',
        addressLine1: 'Fukuoka System LSI Development Center' ,
        addressLine2: '3-8-33 Momochihama',
        addressLine3: 'Sawara-ku Fukuoka City Fukuoka',
        country: 'Japan 814-0001',
        orgHead: 'Mr. Atsushi Yamada @ Suhaimi bin Abdul Rashid',
        orgDes: '(Chairman)',
        tel: '+81 90-4136-3876',
        email: 'info.jhua@gmail.com',
        image: '/images/jhua.PNG',
        website: 'www.japahalalunitassociation.com'
    },
    {
        id: 4,
        name: 'Japan Islamic Trust (JIT)',
        addressLine1: '3-42-7' ,
        addressLine2: 'Minami Otsuka',
        addressLine3: 'Toshima-ku',
        country: 'Japan 170-0005',
        orgHead: 'Mr. Aquil Ahmed Siddiqui',
        orgDes: '(President)',
        tel: '+81 9024556253',
        email: 'info@islam.or.jp',
        email2: 'japanislamictrust.halal@gmail.com',
        image: '/images/jit.PNG',
    },
    {
        id: 5,
        name: 'Muslim Professional Japan Association (MPJA)',
        addressLine1: 'Yoshioka Build 3F' ,
        addressLine2: '4-32-1 Yotsuya',
        addressLine3: 'Shinjuku-ku',
        country: 'Tokyo, Japan 160-0004',
        orgHead: 'Mr. Akmal Abu Hassan',
        orgDes: '(President)',
        tel: '+813-68695775',
        fax: '+813-62748392',
        email: 'info@mpja.jp',
        image: '/images/mpja.PNG',
    },
    {
        id: 6,
        name: 'Nippon Asia Halal Association (NAHA)',
        addressLine1: '2F, SR Matsunami Bldg' ,
        addressLine2: '2-6-2 Matsunami Chuo-ku',
        addressLine3: 'Chiba-shi 260-0044',
        country: 'Japan',
        orgHead: 'Dr. Saeed Akhtar',
        orgDes: '(Chairman)',
        tel: ' 0354138418 / 0432054995',
        fax: ' 0432054996',
        email: 'info@nipponasia-halal.org',
        email2:'akhtar.naha@gmail.com',
        image: '/images/naha.PNG',
    },
    {
        id: 7,
        name: 'Japan Halal Foundation (JHF)',
        addressLine1: 'As-Salaam Bldg. 1F, 4-6-7' ,
        addressLine2: 'Taito Taiko-ku',
        addressLine3: 'Tokyo 110-0016',
        country: 'Japan',
        orgHead: 'Mr. Mohamed Nazeer',
        orgDes: '(President)',
        tel: '050-3644-1045',
        fax: ' 0432054996',
        email: 'info@japanhalal.or.jp',
        website:'www.japanhalal.or.jp',
        image: '/images/jhf.PNG',
    }
]