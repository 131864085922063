import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase-config";
import { deleteDoc, doc } from "firebase/firestore";
import { AuthContext } from "../../Context/Auth";
import { showShortDesc } from "../../utils/utils";
import styles from "./EventItem.module.css";
import EventItemModal from "../EventItemModal/EventItemModal";
export default function EventItem({ event }) {
  const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  function deleteEvent(e, eventId) {
    e.preventDefault();
    const eventData = doc(db, "events", eventId);
    const deleteEvent = async () => {
      await deleteDoc(eventData)
        .then(() => {
          alert("Deleted Succefully");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deleteEvent();
  }
  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  return (
    <div className={styles.event_wrapper}>
      <div className={"row " + styles.event}>
        <div className={styles.image_wrapper}>
          <img
            src={event.image ? event.image : "./images/placeholder.jpg"}
            alt={event.title}
          />
        </div>
        <div className={styles.content}>
          <div>
            {currentUser && (
              <div className="change-buttons">
                <Link className="edit-button" to={`/event/${event.id}/edit`}>
                  <i className="far fa-edit"></i>
                </Link>
                <button
                  className="delete-button"
                  onClick={(e) => {
                    deleteEvent(e, event.id);
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            )}
            <h2>{event.title}</h2>

            <p
              dangerouslySetInnerHTML={{
                __html: showShortDesc(event.description, 100),
              }}
              className="post-desc"
            />
          </div>
        </div>
        <div className={styles.cta}>
          {event.longTermEvent ? (
            <Fragment>
              {event.weekday === "日" ? (
                <span>
                  毎日, {tConvert(event.startAt)} から {tConvert(event.endAt)}
                </span>
              ) : (
                <span>
                  毎週 {event.weekday}, {tConvert(event.startAt)} から{" "}
                  {tConvert(event.endAt)}
                </span>
              )}
            </Fragment>
          ) : (
            <span>
              {event.eventDate &&
                new Date(event.eventDate.seconds * 1000).toLocaleString()}
            </span>
          )}
          <div
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            <span>Read More</span>
          </div>
        </div>
      </div>
      {showModal && (
        <EventItemModal
          event={event}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}
