// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore'
import {getAuth} from '@firebase/auth'
import {getStorage} from '@firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWtRObnNN97lUEAWZIpEOd_Ye-Jbl-3ec",
  authDomain: "jdc-web-system.firebaseapp.com",
  projectId: "jdc-web-system",
  storageBucket: "jdc-web-system.appspot.com",
  messagingSenderId: "465322764482",
  appId: "1:465322764482:web:2797aed4bfb2e962f1735b",
  measurementId: "G-TQXCXV4VXQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);  
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app)
export {auth, db, storage}
