import React, { useEffect, useState } from "react";
import styles from "./EventItemModal.module.css";
export default function EventItemModal({ event, showModal, setShowModal }) {
  const [closeAnimation, setCloseAnimation] = useState(false);
  function handleClose() {
    setCloseAnimation(true);
    setTimeout(() => {
      setShowModal(!showModal);
    }, 300);
  }
  useEffect(() => {
    if (showModal) {
      document.querySelector("html").classList.add("overflow-hidden");
    }
    return () => {
      document.querySelector("html").classList.remove("overflow-hidden");
    };
  }, [showModal]);
  return (
    <div
      className={
        closeAnimation
          ? styles.modal_wrapper + " " + styles.modal_close
          : styles.modal_wrapper
      }
      onClick={() => {
        handleClose();
      }}
    >
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          <p dangerouslySetInnerHTML={{ __html: event.description }} />
        </div>
        <div className={styles.cta}>
          <button
            className={styles.close}
            onClick={() => {
              handleClose();
            }}
          >
            <span>Close</span>
          </button>
          <button>
            <a href={event.link} target="_blank" rel="noopener noreferrer">
              {event.linkTitle ? event.linkTitle : "Learn More"}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
