import React from "react";
import PostsWrapper from "../../Components/PostsWrapper/PostsWrapper";
import CategoryScroll from "../../Components/CategoryScroll/CategoryScroll";
import useLoadPosts from "../../hooks/useLoadPosts";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

export default function Posts() {
  const {
    posts,
    dataLoaded,
    lastVisible,
    loadMoreData,
    loadPostData,
    categoryName,
  } = useLoadPosts();

  return (
    <>
      <SectionHeader>
        <h1 className="title">Posts</h1>
      </SectionHeader>
      <section className="posts">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <CategoryScroll loadPostData={loadPostData} cat={categoryName} />
            </div>
            <div className="col-md-10">
              <PostsWrapper
                posts={posts}
                dataLoaded={dataLoaded}
                lastVisible={lastVisible}
                loadMoreData={loadMoreData}
                categoryName={categoryName}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
