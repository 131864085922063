import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import React, { Component, useState } from "react";
import { db, storage } from "../../firebase-config";
import { useNavigate } from "react-router-dom";

export default function CreateEvents() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [timing, setTiming] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [longTermEvent, setLongTermEvent] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [weekday, setWeekday] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");

  function handleImage(e) {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    setButtonText("Submitting");
    const addImage = () => {
      const storageRef = ref(storage, `images/events/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addEvent(downloadURL);
          });
        }
      );
    };
    addImage();
    const postDesc = "<p>" + description.replace(/\n/g, "</p>\n<p>") + "</p>";
    const addEvent = async (url) => {
      const eventRef = collection(db, "events");
      await addDoc(eventRef, {
        title: title,
        longTermEvent: longTermEvent,
        description: postDesc,
        link: eventLink,
        linkTitle: linkTitle,
        eventDate: date && new Date(date),
        weekday: weekday,
        startAt: startAt,
        endAt: endAt,
        image: url,
        creationDate: new Date(),
      });
      setButtonText("Submitted!!");
      navigate("/events");
    };
  }
  const weekdays = [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ];

  return (
    <section className="create-event">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="form">
              <h1 className="heading">Create a New Event</h1>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="Title">Title</label>
                  <input
                    type="text"
                    placeholder="Title"
                    name="title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Continous Event">Continous Event</label>
                  <label className="check-box">
                    <input
                      type="checkbox"
                      name="long-term-event"
                      id="long-term-event"
                      defaultChecked={longTermEvent}
                      onChange={(e) => setLongTermEvent(!longTermEvent)}
                    />
                    <span className="check-box-slider"></span>
                  </label>
                </div>
                <div className="form-group">
                  {longTermEvent ? (
                    <>
                      <label htmlFor="Timing">Timing</label>
                      <select
                        name="weekday"
                        id="weekday"
                        onChange={(e) => {
                          setWeekday(e.target.value);
                        }}
                      >
                        <option value="日">毎日</option>
                        {weekdays.map((day) => (
                          <>
                            <option value={day}>{day}</option>
                          </>
                        ))}
                      </select>

                      <input
                        type="time"
                        name="start at"
                        id="start_at"
                        placeholder="Start at"
                        onChange={(e) => {
                          setStartAt(e.target.value);
                        }}
                      />
                      <input
                        type="time"
                        name="end at"
                        id="end_at"
                        placeholder="End at"
                        onChange={(e) => {
                          setEndAt(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <label htmlFor="Date">Date</label>
                      <input
                        type="datetime-local"
                        name="date"
                        id="date"
                        placeholder="12th March 2022 at 12:00 PM"
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="text-area">Description</label>
                  <textarea
                    name="text-area"
                    id=""
                    cols="30"
                    rows="10"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    placeholder="Add a Description"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="cta">Link</label>
                  <input
                    type="url"
                    name="Cta"
                    id="cta"
                    placeholder="https://www.zoom.us"
                    onChange={(e) => {
                      setEventLink(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="link-title">Link Title</label>
                  <input
                    type="text"
                    name="link-title"
                    id="link-title"
                    placeholder="Join Us"
                    onChange={(e) => {
                      setLinkTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group upload-image">
                  <label htmlFor="image">Upload an Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    alt="Event Image"
                    onChange={handleImage}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="form-btn">
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
