import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/Auth";

const AuthenticatedRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser === undefined) {
      return;
    }
    if (!currentUser) {
      navigate("/login");
      return;
    }
  }, [currentUser]);
  return children;
};

export default AuthenticatedRoute;
