import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import { db, storage } from "../../firebase-config";

import Resizer from "react-image-file-resizer";
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import { useNavigate, useParams } from "react-router-dom";

export default function Edit() {
  const navigate = useNavigate();
  const postId = useParams().id;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hasImage, setHasImage] = useState(false);
  const [post, setPost] = useState("");
  const [category, setCategory] = useState([]);
  const [postImage, setPostImage] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [embedLink, setEmbedLink] = useState("");
  const [postCategory, setPostCategory] = useState("");
  const [updateImage, setUpdateImage] = useState([]);

  function handleImage(e) {
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      for (let i = 0; i < e.target.files.length; i++) {
        try {
          Resizer.imageFileResizer(
            e.target.files[i],
            1000,
            1000,
            "JPEG",
            100,
            0,
            (uri) => {
              setUpdateImage((prevState) => [...prevState, uri]);
            },
            "file"
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
  function deleteImage(img) {
    return (event) => {
      event.preventDefault();
      const updatedImages = postImage.filter(function (image) {
        return image !== img;
      });
      setPostImage(updatedImages);
      const imageToDelete = ref(storage, img);
      deleteObject(imageToDelete)
        .then(() => {
          console.log("deleted" + imageToDelete);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }

  function handleSubmit(e) {
    e.preventDefault();
    const postDesc = description;
    const postToEdit = doc(db, "posts", postId);
    setButtonText("Submitting");
    const changeImage = () => {
      const image = updateImage;
      return image.map((updateImg) => {
        const storageRef = ref(storage, `images/posts/${updateImg.name}`);
        const uploadTask = uploadBytes(storageRef, updateImg);
        return uploadTask;
      });
    };

    const updatePost = async (postImage) => {
      await updateDoc(postToEdit, {
        title: title,
        description: postDesc,
        image: post.hasImage && postImage,
        category: postCategory,
        videoLink: embedLink ? embedLink : post.videoLink,
        thumbnail: !post.hasImage && postImage,
      });
    };
    if (updateImage.length > 0) {
      let uploadTasks = changeImage();
      uploadTasks.map((task) => task.then(/*change state*/));

      Promise.all(uploadTasks).then((results) => {
        let urls = results.map((result) => getDownloadURL(result.ref));
        let newImages = postImage;
        Promise.all(urls).then((urls) => {
          if (postImage === false) {
            newImages = [...urls];
          } else {
            newImages = [...newImages, ...urls];
            setPostImage((prevState) => [...prevState, ...urls]);
          }

          updatePost(newImages).then(() => {
            setButtonText("Submited!!!!");
            navigate("/posts");
          });
        });
      });
    } else {
      const newUrls = postImage;
      updatePost(newUrls).then(() => {
        setButtonText("Submited!!!!");
        navigate("/posts");
      });
    }
  }
  useEffect(() => {
    const postsCollection = doc(db, "posts", postId);
    const getPosts = async () => {
      const data = await getDoc(postsCollection);
      if (data.exists()) {
        setPost(data.data());
        setPostImage(data.data().image);
        setTitle(data.data().title);
        setDescription(data.data().description);
        setHasImage(data.data().hasImage);
        setPostCategory(data.data().category);
      }
    };
    getPosts();
    const categoryList = doc(db, "categories", "category");
    const getCategory = async () => {
      const fetchedCategory = await getDoc(categoryList);
      if (fetchedCategory.exists()) {
        setCategory(fetchedCategory.data());
      }
    };
    getCategory();
  }, []);

  const categoryArr = category.category;
  const des =
    post.description && post.description.replace(/<p>(.*)<\/p>/g, "$1\n");
  return (
    <div>
      <section className="edit-post">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form">
                <h1 className="heading">Edit</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                      type="text"
                      defaultValue={post.title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      defaultValue={des}
                      onChange={(e) => {
                        setDescription(
                          "<p>" +
                            e.target.value.replace(/\n/g, "</p>\n<p>") +
                            "</p>"
                        );
                      }}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="category">Category:</label>
                    <select
                      name=""
                      id=""
                      defaultValue={post.category}
                      onChange={(e) => {
                        setPostCategory(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select a Category
                      </option>
                      <option value={post.category}>{post.category}</option>
                      {categoryArr &&
                        categoryArr.map((cat) => (
                          <option value={cat}>{cat}</option>
                        ))}
                    </select>
                  </div>
                  {!post.hasImage ? (
                    <>
                      <div className="form-group">
                        <label htmlFor="Link">Embed Link</label>
                        <input
                          type="text"
                          defaultValue={post.videoLink}
                          onChange={(e) => {
                            setEmbedLink(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group upload-image">
                        <label htmlFor="image">Change Thumbnail</label>
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          alt="Post Image"
                          onChange={handleImage}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="form-group upload-image">
                      <label htmlFor="image">Add Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        name="image"
                        alt="Post Image"
                        multiple
                        onChange={handleImage}
                      />
                    </div>
                  )}

                  <div className="form-group">
                    <button type="submit" className="form-btn">
                      {buttonText}
                    </button>
                  </div>
                </form>
                {post.hasImage && (
                  <div className="row edit-img-wrapper">
                    {postImage &&
                      postImage.map((img) => (
                        <div className="col-md-3">
                          <img src={img} alt="" />
                          <form onSubmit={deleteImage(img)}>
                            <button type="submit" className="delete-button">
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </form>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
