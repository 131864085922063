import React from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "./masjidAlDawah.styles.css";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

export default function masjidAlDawah() {
  return (
    <div>
      <SectionHeader>
        <h1 className="title">Masjid Al Da'wah</h1>
      </SectionHeader>

      <section className="light-grey fundraise">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-wrapper">
                <h3 className="heading">Fundraising Campaign to</h3>
                <h3 className="pre-heading">
                  Build Our First Masjid with Japanese Concept in Japan
                </h3>
                <p className="text">
                  There are still misconceptions about Islam in Japan. We uphold
                  diversity and tolerance as our values in doing Da'wah. With
                  Japanese concept, we hope to blend in with the locals and
                  reach more people.
                </p>
              </div>
            </div>
            <div className="col-md-6 collected-funds text-center">
              <div className="text-wrapper">
                <i className="fas fa-bullseye"></i>
                <p className="target">
                  {" "}
                  Needed Atleast <span>MYR 8,00,000</span> For The Down Payment
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row align-center text-center">
            <div className="col-md-12">
              <h4 className="pre-heading">SUPPORTED BY</h4>
            </div>
            <div className="col-md-2 col-xs-6">
              <img
                className="support-logo"
                src="./images/image-013.jpg"
                alt="logo"
              />
            </div>
            <div className="col-md-2 col-xs-6">
              <img
                className="support-logo"
                src="./images/fajar-iman.jpg"
                alt="logo"
              />
            </div>
            <div className="col-md-2 col-xs-6">
              {" "}
              <img
                className="support-logo"
                src="./images/japan-muslim.jpg"
                alt="logo"
              />
            </div>
            <div className="col-md-2 col-xs-6">
              {" "}
              <img
                className="support-logo"
                src="./images/dawah-islam-di-bumi.jpg"
                alt="logo"
              />
            </div>
            <div className="col-md-2 col-xs-6">
              {" "}
              <img
                className="support-logo"
                src="./images/nippon-muslim.jpg"
                alt="logo"
              />
            </div>
            <div className="col-md-2 col-xs-6">
              <img
                className="support-logo"
                src="./images/awqaf-japan.jpg"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 row">
              <div className="bank-card">
                <img src="./images/cimb-bank.png" alt="" />
                <p className="account-number">8603519647</p>
                <p>(DidBMT ENTERPRISE)</p>
                <p>
                  Please Write down the Purpose of transaction as <br />
                  <strong>(Masjid Dawah/ Infaq/ Zakat/Waqaf)</strong>
                </p>
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="bank-card">
                <img src="./images/smbc.png" alt="" />
                <p className="bank-d">
                  SMBC (Mitsui Sumitomo Bank) Ippan Shadan Houjin{" "}
                </p>
                <p className="det">Japan Dawah Centre</p>
                <p>
                  Branch <strong>Kawachinagano</strong> (164) <br />
                  Futsu Account No: <strong>1679638</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mosque-quote light-grey">
        <div className="container">
          <div className="quote text-center">
            <h2 className="pre-heading">
              The Prophet Muhammad, peace be upon him, said
            </h2>
            <blockquote>
              Whoever builds a mosque for Allah, Allah will build <br />
              for him a house like it in Paradise.
            </blockquote>
            <p>[SAHIH MUSLIM]</p>
          </div>
          <div className="row align-center">
            <div className="col-md-6">
              <p className="para">
                {" "}
                Every penny spend on the donation for Masjid Al Da'wah counts
                and brings us closer to His Paradise, Insha Allah{" "}
              </p>
            </div>

            <div className="col-md-6 text-center">
              <img
                className="masjid-image"
                src="./images/mosque-vector.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container text-center">
          <div className="quote">
            <h2 className="heading">ALLAH SWT MENTIONED</h2>
            <h3 className="arabic">
              الَّذِينَ يُنفِقُونَ أَمْوَالَهُم بِاللَّيْلِ وَالنَّهَارِ سِرًّا
              وَعَلاَنِيَةً فَلَهُمْ أَجْرُهُمْ عِندَ رَبِّهِمْ وَلاَ خَوْفٌ
              عَلَيْهِمْ وَلاَ هُمْ يَحْزَنُونَ
            </h3>
            <blockquote>
              "Those who (in charity) spend of their goods by night and by day,
              in secret and in public, have their reward with their Lord: on
              them shall be no fear, nor shall they grieve"
            </blockquote>
            <p>[2:274]</p>
          </div>
        </div>
      </section>
      <section className="pie-chart light-grey">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6">
              <img src="./images/piec.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h2 className="heading">Islam in Japan</h2>
                <p>Islam is categorized as a minor religion.</p>
                <p>
                  <strong>Unfortunately,</strong> Islam is also misunderstood as
                  religion of violence, women oppression and terrorism as those
                  reported by media.
                </p>
              </div>
            </div>
            <div className="col-md-12 text-center mt-20">
              <p>
                As Muslims living in Japan, it is our responsibility to spread
                the beauty of Islamic values to them and fixed the stigmas
                surrounding Islam.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="map-section no-padding">
        <div className="container-fluid">
          <div className="row align-center">
            <div className="col-md-6 mobile-order-2">
              <div className="content">
                <p>
                  With the grace of Allah and continous support from others,
                  after the establishment of Japan Da'wah Centre, we are
                  committed to build Masjid Al-Da'wah. The world's first mosque
                  that will be adopting Japanese Design for its architecture.
                  Masjid Al-Da'wah will be the third mosque to exist in Osaka.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="./images/map.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="light-grey">
        <div className="container">
          <div className="row align-center">
            <div className="content col-md-12 text-center">
              <img className="logo-image" src="./images/Logo.png" alt="" />
              <h3 className="pre-heading">EMPOWERING DA'WAH ENVIRONMENT</h3>
              <h2 className="heading">CONSTRUCTION PROJECT</h2>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h2 className="heading">OUR MAIN FOCUS</h2>
                <p>
                  To Build a <strong>"One Stop Center"</strong> that Offers:
                </p>
                <ul className="list-items">
                  <li>One-stop centre for religious activities</li>
                  <li>Islamic da'wah dissemination centre</li>
                  <li>Welfare and community centre</li>
                  <li>Islamic knowledge centre</li>
                  <li>Economy centre for locals</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-box">
                <p>
                  Most of the mosques in Japan were built by immigrants in which
                  they adopted foreign cultures. This has caused Islam to be
                  viewed as "irrelevant" among Japanese people.
                </p>
                <p>
                  These mosques are only treated as worship centre for Muslim
                  foreigners. We want to change this norm through a mosque
                  imbued with Japanese architecture and culture, as it will be
                  the first one in Japan. We also wish to create more
                  opportunities for Japanese people to be more active in mosque
                  activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cards">
        <div className="container">
          <h2 className="heading">Our Targets</h2>
          <div className="row">
            <div className="col-md-3">
              <h4 className="card-heading">2nd Genration Muslim</h4>
              <p>
                Some of these youths are said to be losing their Islamic
                identity as they are raised in Japan. They go through challenges
                living as a Muslim in Japan. Raising
              </p>
            </div>
            <div className="col-md-3">
              <h4 className="card-heading">Muslimah (Women)</h4>
              <p>
                Most mosques in Japan are pioneered by men while women have
                little role in it.
              </p>
              <p>
                We hope to change this norm by creating more opportunities for
                women to have bigger roles in Mosque for Women visitors as they
                have big opportunity to educate them for the future generation.
              </p>
            </div>
            <div className="col-md-3">
              <h4 className="card-heading">Converted Muslim (Muallaf)</h4>
              <p>In Japan, there are more female converts than males. </p>
              <p>
                However, there are limited spaces for them in Mosque, be it to
                learn Islamic knowledge or to associate with fellow Muslimah. We
                aim to make a comfortable space for Muslimah and female Japanese
                too who are interested in learning about Islam.
              </p>
            </div>
            <div className="col-md-3">
              <h4 className="card-heading">Islam & Local Culture</h4>
              <p>
                This mosque will reflect Japanese design and architecture, the
                first of its kind in Japan. 
              </p>
              <p>
                By incorporating Japanese-style, it would help to make Japanese
                people feel "at-home" when visiting mosque. Also, to promote
                Islam as a religion for all instead of a 'foreign religion'.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="cards light-grey our-mission">
        <div className="container">
          <h2 className="heading">Our Mission</h2>
          <div className="row">
            <div className="col-md-3">
              <p>Spread Islam Towards Our Surrounding Community</p>
            </div>
            <div className="col-md-3">
              <p>Inspiring through Islamic Values</p>
              <p>Youth Empowerment</p>
            </div>
            <div className="col-md-3">
              <p>
                Educating Community, Second Generation Muslim, and Future
                Leaders
              </p>
            </div>
            <div className="col-md-3">
              <p>
                Incorporating Japanese-style to make Japanese people feel
                "at-home"
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="concept-examples">
        <div className=" container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="heading concept-mobile-heading">
                Concept Examples
              </h2>
            </div>
            <div className="concept-wrapper-1 row">
              <h2 className="concept-text">Concept Examples</h2>
              <div className="col-md-6">
                <img src="./images/concept-1.jpg" alt="" />
              </div>
              <div className="col-md-6">
                <img src="./images/concept2.jpg" alt="" />
              </div>
              <div className="col-md-6">
                <img src="./images/concept3.jpg" alt="" />
              </div>
              <div className="col-md-6">
                <img src="./images/concept4.png" alt="" />
              </div>
            </div>

            <div className="col-md-3">
              <img src="./images/concept5.jpg" alt="" />
            </div>
            <div className="col-md-3">
              <img src="./images/concept6.jpg" alt="" />
            </div>
            <div className="col-md-3">
              <img src="./images/concept7.png" alt="" />
            </div>
            <div className="col-md-3">
              <img src="./images/concept8.jpg" alt="" />
            </div>
            <div className="col-md-6">
              <img src="./images/concept9.jpg" alt="" />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h2 className="pre-heading">Facilities</h2>
                <ul>
                  <li>Prayer Space</li>
                  <li>Da'wah Studio</li>
                  <li>Small Library</li>
                  <li>Learning Centre</li>
                  <li>Muslim Community Center</li>
                  <li>Islamic Economy Centre</li>
                  <li>Cafetaria & Halal Shop</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-50">
              <h2 className="heading">What They Say?</h2>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="testimonial-wrapper">
                <div className="icon">
                  <img src="./images/user-male.png" alt="" />
                </div>
                <div className="text">
                  <p>
                    “Japan Da'wah Centre is an example that can be followed by
                    many mosques in Japan in terms of openness when conducting
                    activities. Hopefully with Masjid Al Da'wah, more people
                    will be attracted to Islam”
                  </p>
                  <p>University Student</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-wrapper">
                <div className="icon">
                  <img src="./images/user-female.png" alt="" />
                </div>
                <div className="text">
                  <p>
                    “I come to Japan Da'wah Centre for almost every week to
                    study about Islam and it is such a fun experience. It feels
                    as if JDC is my second home. Surely Masjid Al-Da'wah will be
                    able to attract more Japanese citizens to come."
                  </p>
                  <p>University Student</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-wrapper">
                <div className="icon">
                  <img src="./images/user-male.png" alt="" />
                </div>
                <div className="text">
                  <p>
                    “There were various activities done by JDC. Every month we
                    were informed that there will be new reverts. I believe with
                    the establishment of Masjid Al-Da'wah, Islamic da'wah will
                    be spread widely in Japan, Insya Allah."
                  </p>
                  <p>Executive</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-wrapper">
                <div className="icon">
                  <img src="./images/user-male.png" alt="" />
                </div>
                <div className="text">
                  <p>
                    “When learning about Islam, there will be an interest to
                    seek for information. With Masjid Al-Da'wah, it will
                    certainly invoke such interest to learn more about Islam."
                  </p>
                  <p>University Student</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">Let's Donate</h2>
              <p>
                Collected fund for the down payment are urgently needed to
                secure the land. As soon as the amount is achieved, the desired
                location can be purchased and the construction project for
                Masjid Al-Da'wah can be resumed. With your kind donation, many
                Japanese Muslims, new reverts, and all Muslims can benefit from
                it.
              </p>
              <p>
                Come join us in building Masjid Al-Da'wah. Mosques in Japan are
                considered as 'foreign' to the locals as they were influenced by
                foreign cultures. Let's join hand to help the religion of Allah.
              </p>
              <div className="hadith">
                <p>
                  <span>
                    “Whoever builds a mosque for Allah, Allah will build for him
                    a house like it in Paradise.”
                  </span>
                </p>
                <p>(Sahih Muslim)</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pexels">
        <div className="container-fluid">
          <div className="row align-center">
            <div className="col-md-6 mobile-order-2">
              <div className="content">
                <h2 className="super-heading">
                  "Masjid Al-Da'wah is My Second Home"
                </h2>
                <h3 className="pre-heading">This is our motto</h3>
                <p>
                  We aim to give off the feeling of being at "at-home".
                  <br />
                  With such familiarity, whenever Japanese people visit Masjid
                  Al-Da'wah,
                  <br />
                  they will be able to regard it as their second home
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/images/concept9.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6 mobile-order-2">
              <div className="content">
                <p className="pre-heading">
                  MAY ALLAH REWARD YOU ABUNDANTLY FOR YOUR GENEROUS
                  CONTRIBUTION.
                </p>
                <p className="prayer">
                  <blockquote>(AAMIIN YA RABBAL A'LAMIN)</blockquote>{" "}
                </p>
              </div>
              <div className="row">
                <div className="col-md-12 row">
                  <div className="bank-card">
                    <img src="./images/cimb-bank.png" alt="" />
                    <p className="account-number">8603519647</p>
                    <p>(DidBMT ENTERPRISE)</p>
                    <p>
                      Please Write down the Purpose of transaction as <br />
                      <strong>(Masjid Dawah/ Infaq/ Zakat/Waqaf)</strong>
                    </p>
                  </div>
                </div>
                <div className="col-md-12 row">
                  <div className="bank-card">
                    <img src="./images/smbc.png" alt="" />
                    <p className="bank-d">
                      SMBC (Mitsui Sumitomo Bank) Ippan Shadan Houjin{" "}
                    </p>
                    <p className="det">Japan Dawah Centre</p>
                    <p>
                      Branch <strong>Kawachinagano</strong> (164) <br />
                      Futsu Account No: <strong>1679638</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img className="prayer-image" src="/images/shahdah.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
