import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { Component, useEffect, useState } from "react";
import { db, storage } from "../../firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";

export default function EventEdit() {
  const [buttonText, setButtonText] = useState("Submit");
  const [event, setEvent] = useState([]);
  const [date, setDate] = useState("");
  const [weekday, setWeekday] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [newDate, setNewDate] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eventImage, setEventImage] = useState("");
  const eventId = useParams().id;
  const navigate = useNavigate();
  function handleImage(e) {
    if (e.target.files[0]) {
      setUpdateImage(e.target.files[0]);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    const eventDesc = description;
    const updatedDate = newDate ? new Date(newDate) : date;

    const eventItem = doc(db, "events", eventId);
    setButtonText("Submitting");
    const changeImage = () => {
      const image = updateImage;
      const storageRef = ref(storage, `images/events/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            updateEvent(downloadURL).then(() => {
              setButtonText("Submited!!!!");
              navigate("/events");
            });
          });
        }
      );
    };
    changeImage();

    const updateEvent = async (url) => {
      console.log({
        title: title,
        description: eventDesc,
        image: updateImage ? url : event.image,
        eventDate: updatedDate,
        weekday: weekday,
        startAt: startAt,
        endAt: endAt,
        linkTitle: linkTitle,
      });
      await updateDoc(eventItem, {
        title: title,
        description: eventDesc,
        image: updateImage ? url : event.image,
        eventDate: updatedDate ? updatedDate : "",
        weekday: weekday ? weekday : "",
        startAt: startAt ? startAt : "",
        endAt: endAt ? endAt : "",
        linkTitle: linkTitle ? linkTitle : "",
      });
    };
  }
  useEffect(() => {
    const eventItem = doc(db, "events", eventId);
    const getEvent = async () => {
      const data = await getDoc(eventItem);
      if (data.exists()) {
        setEvent(data.data());
        setEventImage(event.image);
        setTitle(event.title);
        setDescription(event.description);
        setDate(event.eventDate);
        setWeekday(event.weekday);
        setStartAt(event.startAt);
        setEndAt(event.endAt);
        setEventLink(event.link);
        setLinkTitle(event.linkTitle);
      }
    };
    getEvent();
  }, []);
  const weekdays = [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ];
  const des =
    event.description && event.description.replace(/<p>(.*)<\/p>/g, "$1\n");

  return (
    <>
      <section className="edit-post">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form">
                <h1 className="heading">Edit</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                      type="text"
                      defaultValue={event.title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      defaultValue={des}
                      onChange={(e) => {
                        setDescription(
                          "<p>" +
                            e.target.value.replace(/\n/g, "</p>\n<p>") +
                            "</p>"
                        );
                      }}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    {event.longTermEvent ? (
                      <>
                        <label htmlFor="Timing">Timing</label>
                        <select
                          name="weekday"
                          id="weekday"
                          onChange={(e) => {
                            setWeekday(e.target.value);
                          }}
                          defaultValue={event.weekday}
                        >
                          <option value="日">毎日</option>
                          {weekdays.map((day) => (
                            <>
                              <option value={day}>{day}</option>
                            </>
                          ))}
                        </select>

                        <input
                          type="time"
                          name="start at"
                          id="start_at"
                          placeholder="Start at"
                          onChange={(e) => {
                            setStartAt(e.target.value);
                          }}
                          defaultValue={event.startAt}
                        />
                        <input
                          type="time"
                          name="end at"
                          id="end_at"
                          placeholder="End at"
                          onChange={(e) => {
                            setEndAt(e.target.value);
                          }}
                          defaultValue={event.endAt}
                        />
                      </>
                    ) : (
                      <>
                        <label htmlFor="Date">Date</label>
                        <input
                          lang="jp"
                          type="datetime-local"
                          name="date"
                          id="date"
                          placeholder="12th March 2022 at 12:00 PM"
                          onChange={(e) => {
                            setNewDate(e.target.value);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="cta">Link</label>
                    <input
                      type="url"
                      name="Cta"
                      id="cta"
                      placeholder="https://www.zoom.us"
                      onChange={(e) => {
                        setEventLink(e.target.value);
                      }}
                      defaultValue={event.link}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="link-title">Link Title</label>
                    <input
                      type="text"
                      name="link-title"
                      id="link-title"
                      placeholder="Join Us"
                      onChange={(e) => {
                        setLinkTitle(e.target.value);
                      }}
                      defaultValue={event.linkTitle}
                    />
                  </div>
                  <div className="form-group upload-image">
                    <label htmlFor="image">Change Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      alt="Event Image"
                      onChange={handleImage}
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="form-btn">
                      {buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
