import React, { Fragment, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import styles from "./Filters.module.css";
export default function Filters({ title, current, handleTabChange, arr }) {
  const [showAccordion, setShowAccordion] = useState(true);
  const [closeAccordion, setCloseAccordion] = useState(false);
  function handleAccordion() {
    if (showAccordion) {
      setCloseAccordion(true);
      setTimeout(() => {
        setShowAccordion(false);
      }, 400);
    } else {
      setCloseAccordion(false);
      setShowAccordion(true);
    }
  }
  return (
    <div className={styles.filter_scroll}>
      <h4
        onClick={() => {
          handleAccordion();
        }}
      >
        {title}
        <FaAngleDown />
      </h4>
      {showAccordion && (
        <div
          className={
            closeAccordion
              ? styles.filters + " " + styles.close
              : styles.filters
          }
        >
          <div className={styles.filter}>
            <span
              className={current == "all" ? styles.active : ""}
              onClick={() => {
                handleTabChange("all");
                if (window.innerWidth < 768) {
                  handleAccordion();
                }
              }}
            >
              All
            </span>
          </div>
          {arr &&
            arr.map((item, id) => (
              <div className={styles.filter} key={id}>
                <span
                  className={current == item ? styles.active : ""}
                  onClick={() => {
                    handleTabChange(item);
                    if (window.innerWidth < 768) {
                      handleAccordion();
                    }
                  }}
                >
                  {item}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
