import React, { Fragment, useEffect, useState } from "react";
import { db } from "../../firebase-config";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
} from "@firebase/firestore";
import Post from "../../Components/Post/Post";
import CategoryScroll from "../../Components/CategoryScroll/CategoryScroll";
import { useParams } from "react-router-dom";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
export default function Category() {
  const [posts, setPosts] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [catUpdated, setCatUpdated] = useState(false);
  const params = useParams();
  const categoryName = params.categoryName.replaceAll("-", " ");
  useEffect(() => {
    const postsCollection = query(
      collection(db, "posts"),
      where("category", "==", categoryName),
      orderBy("date", "desc")
    );
    const getPosts = async () => {
      const data = await getDocs(postsCollection);
      setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getPosts().then(() => {
      setDataLoaded(true);
      setCatUpdated(true);
    });
  }, [params]);
  return (
    <>
      <SectionHeader>
        <h1 className="title">{categoryName}</h1>
      </SectionHeader>
      {dataLoaded && (
        <section className="posts">
          <div className="container">
            <div className="row">
              <CategoryScroll />

              {catUpdated && (
                <Fragment>
                  {posts.length === 0 && <h2>no posts</h2>}
                  {posts.map((post, index) => {
                    return (
                      <>
                        <Post post={post} key={index} />
                      </>
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
