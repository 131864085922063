import React from "react";
import styles from "./Intro.module.css";
export default function Intro() {
  return (
    <section className={"intro " + styles.intro}>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-md-10">
            <h2 className="heading">イスラムがどんな宗教かご存じですか？</h2>
            <p>
              イスラムは愛、優しさ、平和を説いている宗教であり、唯一神アッラーを信仰する宗教です。ムスリムとはイスラム教徒の総称です。ムスリムの行動は主に二つの原則に基づいています。全てを創造した唯一神、アッラーを崇拝すること最後の預言者、ムハンマドを信じること。そして、ジャパンダアワセンターはそこから美しいイスラムの教えと生き方についてイベントやクラスでシェアします。
            </p>
            <p>
              実際のイスラームの教えとメディアで報道されている内容がいかに異なっているのか、またイスラームの教えが日本の道徳や文化といかに近いかを知れば驚きを隠せないでしょう…
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
