import React, { Fragment, useEffect } from "react";
import Post from "../../Post/Post";
import useLoadPosts from "../../../hooks/useLoadPosts";
import styles from "./RecentPosts.module.css";
import { Link } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import { showShortDesc } from "../../../utils/utils";
export default function RecentPosts() {
  const { posts, dataLoaded } = useLoadPosts(4);
  return (
    <Fragment>
      {dataLoaded && (
        <section className="posts">
          <div className="container">
            <div
              className="row align-center"
              style={{ justifyContent: "space-between", marginBottom: "30px" }}
            >
              <h2 className="heading" style={{ margin: "0" }}>
                最新のブログ
              </h2>
              <Link to="/posts" className={styles.link}>
                <span>全て表示</span>
                <GoArrowRight />
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Link to={`/post/${posts[0].id}`} className={styles.firstPost}>
                  <div className={styles.image}>
                    {posts[0].hasImage ? (
                      <img src={posts[0].image[0]} alt="" />
                    ) : (
                      <img
                        src={
                          posts[0].thumbnail.length > 0
                            ? posts[0].thumbnail
                            : "./images/placeholder.jpg"
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className={styles.content}>
                    <p className={styles.category}>{posts[0].category}</p>
                    <h2>{posts[0].title}</h2>

                    <div className={styles.reveal}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: showShortDesc(posts[0].description),
                        }}
                      />
                      {/* <Link
                        to={`/post/${posts[0].id}`}
                        className={styles.button}
                      >
                        <span>
                          <GoArrowRight />
                        </span>
                      </Link> */}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                {posts.map((post, id) => {
                  if (id !== 0) {
                    return (
                      <Link to={`/post/${post.id}`} className={styles.post}>
                        <div className={styles.post_image}>
                          {post.hasImage ? (
                            <img src={post.image[0]} alt="" />
                          ) : (
                            <img
                              src={
                                post.thumbnail.length > 0
                                  ? post.thumbnail
                                  : "./images/placeholder.jpg"
                              }
                              alt=""
                            />
                          )}
                          <span className={styles.hover_icon}>
                            <GoArrowRight />
                          </span>
                        </div>
                        <div className={styles.post_content}>
                          <span className={styles.post_category}>
                            {post.category}
                          </span>

                          <h2>{post.title}</h2>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: showShortDesc(post.description),
                            }}
                          />
                          {/* <Link to={`/post/${post.id}`} className={styles.link}>
                            <span>Read More</span>
                            <GoArrowRight />
                          </Link> */}
                        </div>
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
}
