import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase-config";
import { deleteDoc, doc } from "firebase/firestore";
import { AuthContext } from "../../Context/Auth";
import { showShortDesc } from "../../utils/utils";
export default function Post({ post }) {
  const { currentUser } = useContext(AuthContext);
  function deletePost(e, postId) {
    e.preventDefault();
    const postData = doc(db, "posts", postId);
    const deletePost = async () => {
      await deleteDoc(postData)
        .then(() => {
          alert("Deleted Succefully");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deletePost();
  }

  return (
    <div className="col-md-4 col-sm-6">
      <div className="posts-wrapper">
        <div className="posts-img">
          {post.hasImage ? (
            <img
              src={post.image[0]}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "./images/placeholder.jpg";
              }}
            />
          ) : (
            <img
              src={
                post.thumbnail.length > 0
                  ? post.thumbnail
                  : "./images/placeholder.jpg"
              }
              alt=""
            />
          )}
        </div>
        <div className="posts-content">
          <div className="row">
            <div className="col-md-6">
              <Link
                to={`/category/${post.category.replace(/ /g, "-")}`}
                className="category-link"
              >
                <span className="category">{post.category}</span>
              </Link>
            </div>
            {currentUser && (
              <div className="col-md-6">
                <div className="change-buttons">
                  <Link className="edit-button" to={`/post/${post.id}/edit`}>
                    <i className="far fa-edit"></i>
                  </Link>
                  <button
                    className="delete-button"
                    onClick={(e) => {
                      deletePost(e, post.id);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            )}
          </div>

          <h2>{post.title}</h2>

          <p
            className="post-desc"
            dangerouslySetInnerHTML={{
              __html: showShortDesc(post.description),
            }}
          />
        </div>
        <Link to={`/post/${post.id}`}>
          <span className="read-more">Read More</span>
        </Link>
      </div>
    </div>
  );
}
