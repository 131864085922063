import React, { useState } from "react";
import styles from "./OurMission.module.css";
import { FaPlay } from "react-icons/fa";
import VideoModal from "../../VideoModal/VideoModal";
export default function OurMission() {
  const [showVideoModal, setShowVideoModal] = useState(false);
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.box}>
          <div className="row align-center">
            <div className="col-md-6">
              <div
                className={styles.video_player}
                onClick={() => setShowVideoModal(true)}
              >
                <img src="/images/our-mission.webp" alt="japan dawah centre" />
                <div className={styles.play}>
                  <div className={styles.icon}>
                    <FaPlay />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mission-text">
              <h2 className="heading">私たちのミッション</h2>
              <div className="points">
                <p>近隣コミュニティに向けた正しいイスラームの伝達</p>
                <p>イスラームの価値観を通した元気付け</p>
                <p>若者の可能性を支援</p>
                <p>第二世代のイスラム教徒、そして次世代のリーダーの教育</p>
                <p>
                  日本人にモスクを心地よく感じてもらうための和風を入れた仕組みづくり"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVideoModal && <VideoModal setShowVideoModal={setShowVideoModal} />}
    </section>
  );
}
