import React, { Fragment } from "react";
import { Link } from "react-router-dom";
export default function MainMenuItems(props) {
  return (
    <Fragment>
      <li>
        <Link to="/dawah" onClick={props.closeSidebar}>
          ダアワ活動
        </Link>
      </li>
      <li>
        <Link to="/halal" onClick={props.closeSidebar}>
          ハラール
        </Link>
      </li>
      <li>
        <Link to="/shahadah" onClick={props.closeSidebar}>
          入信したい方
        </Link>
      </li>
      <li>
        <Link to="/japan-dawah-centre" onClick={props.closeSidebar}>
          ジャパン ダアワ センター
        </Link>
      </li>
      <li>
        <Link to="/mosques" onClick={props.closeSidebar}>
          モスク
        </Link>
      </li>
      <li>
        <Link to="/masjid-al-dawah" onClick={props.closeSidebar}>
          Masjid Al Dawah{" "}
        </Link>
      </li>
    </Fragment>
  );
}
