import React, { Fragment } from "react";
import styles from "./MosquesCard.module.css";
import { HiLocationMarker } from "react-icons/hi";
import { FaPhone } from "react-icons/fa6";
export default function MosquesCard(mosques) {
  console.log(mosques);
  return (
    <Fragment>
      {mosques.mosque.map((mosqueDetail, index) => {
        return (
          <div
            className={"col-md-4 col-sm-6 flex " + styles.mosque_wrapper}
            key={index}
          >
            <div className={styles.mosque}>
              <img src={mosqueDetail.image} alt="" />
              <div>
                <h4>{mosqueDetail.mosqueName}</h4>
                <span>
                  <HiLocationMarker />
                  <span>{mosqueDetail.mosqueAddress}</span>
                </span>
                {mosqueDetail.phoneNumber && (
                  <span>
                    <FaPhone />{" "}
                    <span>
                      {mosqueDetail.phoneNumber
                        .split(" / ")
                        .map((phoneNumber, id) => (
                          <a
                            href={`tel:${phoneNumber}`}
                            className={styles.phoneNumber}
                          >
                            {phoneNumber}
                          </a>
                        ))}
                    </span>
                  </span>
                )}
                {mosqueDetail.website && (
                  <a
                    href={mosqueDetail.website}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={styles.link}
                  >
                    Visit Website
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}
