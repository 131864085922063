import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase-config";

const AuthContext = createContext({
  currentUser: undefined,
});

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged(
      (user) => {
        setCurrentUser(user);

        if (user) {
          setIsLoggedIn(true);
        }
      },
      (error) => {
        console.error("Auth state change error:", error);
      }
    );
    console.log(currentUser);
  }, [currentUser]);
  return (
    <AuthContext.Provider
      value={{
        currentUser: currentUser,
        isLoggedIn: isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
