import React, { useContext } from "react";
import logo from "../../assets/logo.png";
import "./Header.styles.css";
import MainMenuItems from "../MainMenuItems/MainMenuItems";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/Auth";

export default function Header({ toggleMenu }) {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="header">
      <div className="row">
        <div className="logo col">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="main-menu col">
          <div className="menu-desktop">
            <ul>
              <MainMenuItems currentUser={currentUser} />
            </ul>
          </div>
          <button className="menu-icon" onClick={toggleMenu}>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
  );
}
