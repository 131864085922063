import { getDoc, doc, updateDoc } from "firebase/firestore";
import React, { Component, useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { useNavigate } from "react-router-dom";

export default function ChangeToken() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [token, setToken] = useState("");
  const [newToken, setNewToken] = useState("");
  const [btnTxt, setBtnTxt] = useState("Update");
  const navigate = useNavigate();
  useEffect(() => {
    const token = doc(db, "insta-token", "token");
    const getToken = async () => {
      const data = await getDoc(token);
      if (data.exists()) {
        setToken(data.data());
      }
    };
    getToken().then(() => {
      setDataLoaded(true);
    });
  }, []);

  function updateToken(e) {
    e.preventDefault();
    setBtnTxt("Updating!!");
    const token = doc(db, "insta-token", "token");
    const changeToken = async () => {
      await updateDoc(token, {
        instagramToken: newToken,
      });
    };
    changeToken().then(() => {
      navigate("/admin");
    });
  }
  return (
    <section>
      {dataLoaded && (
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2 className="pre-heading">Update Instagram Token</h2>
              <form onSubmit={updateToken}>
                <div className="form-group">
                  <label htmlFor="token">Change Token</label>
                  <input
                    type="text"
                    defaultValue={token.instagramToken}
                    onChange={(e) => {
                      setNewToken(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <input type="submit" value={btnTxt} />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
