import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./Hero.module.css";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, EffectFade } from "swiper/modules";
import { LuMoveRight, LuMoveLeft } from "react-icons/lu";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
export default function Hero() {
  return (
    <div className={styles.section}>
      <Swiper
        modules={[Navigation, Autoplay, EffectFade]}
        effect="fade"
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
        }}
        className={styles.hero}
      >
        <SwiperSlide>
          <img src={"./images/hero-1.jpg"} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={"./images/hero-2.jpg"} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={"./images/hero-3.jpg"} alt="" />
        </SwiperSlide>
        <div className={styles.content_wrapper}>
          <div className={styles.content}>
            <h3>Welcome To</h3>
            <h1>Japan Da'wah Centre</h1>
            <p>多様性と調和</p>
            <Link to="/dawah">
              <button>
                <span>詳しく見る</span>
              </button>
            </Link>
          </div>
          <div className={styles.navigation}>
            <button className={"prev " + styles.prev}>
              <LuMoveLeft />
            </button>
            <button className={"next " + styles.prev}>
              <LuMoveRight />
            </button>
          </div>
        </div>
      </Swiper>
    </div>
  );
}
