import React, { Component, useEffect } from "react";

export default function FacebookEmbed() {
  useEffect(() => {
    if (typeof window.FB != "undefined") {
      window.fbAsyncInit();
    }
  }, [window.FB]);

  return (
    <>
      <div
        className="fb-page"
        data-href="https://www.facebook.com/japandawahcentremedia/"
        data-tabs="timeline"
        data-width="600"
        data-height="500"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/japandawahcentremedia/"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/japandawahcentremedia/">
            ジャパンダアワセンターメディア
          </a>
        </blockquote>
      </div>
    </>
  );
}
