import React from "react";
import { Link } from "react-router-dom";
import "./AdminPanel.styles.css";
export default function AdminPanel() {
  return (
    <div className="admin-panel">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="admin-wrapper">
                <h4 className="pre-heading">Posts</h4>
                <div className="link">
                  <Link to="/create-post">
                    <span>Create a New Post</span>
                  </Link>

                  <Link to="/posts">
                    <span>Edit a Post</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="admin-wrapper">
                <h4 className="pre-heading">Events</h4>
                <div className="link">
                  <Link to="/create-event">
                    <span>Create a New Event</span>
                  </Link>

                  <Link to="/events">
                    <span>Edit an Event</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="admin-wrapper">
                <h4 className="pre-heading">Category</h4>
                <div className="link">
                  <Link to="/category">
                    <span>Delete a Category</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="admin-wrapper">
                <h4 className="pre-heading">Social</h4>
                <div className="link">
                  <Link to="/instagram-token">
                    <span>Update Instagram Token</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
