import React, { Fragment, useContext, useEffect, useState } from "react";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";

import { Routes, Route } from "react-router-dom";
import DawahPage from "./pages/dawah/DawahPage";
import HalalPage from "./pages/halal/HalalPage";
import Shahadah from "./Components/shahadah/Shahadah";
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import PostDetail from "./Components/PostDetail/PostDetail";
import CreatePost from "./Components/CreatePost/CreatePost";
import JapanDawahCenter from "./pages/japanDawahCenter/JapanDawahCenter";
import Mosques from "./pages/Mosques/Mosques";
import Login from "./pages/login/login";
import Posts from "./pages/posts/Posts";
import MasjidAlDawah from "./pages/masjid-al-dawah/masjidAlDawah";
import Edit from "./pages/edit/edit";
import Category from "./pages/Category/Category";
import Events from "./pages/Evets/Events";
import CreateEvents from "./pages/CreateEvents/CreateEvents";
import EventEdit from "./Components/EventEdit/EventEdit";
import NotFound from "./pages/NotFound/NotFound";
import Footer from "./Components/Footer/Footer";
import Categories from "./Components/Categories/Categories";
import ChangeToken from "./Components/ChangeToken/ChangeToken";
import ImpersonShahadah from "./Components/ImpersonShahadah/ImpersonShahadah";
import { AuthContext, AuthProvider } from "./Context/Auth";
import AuthenticatedRoute from "./utils/AuthenticatedRoute";
import Home from "./pages/Home/Home";

export default function App() {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <Fragment>
      <AuthProvider>
        <Sidebar openMenu={openMenu} toggleMenu={toggleMenu} />
        <main>
          <Header toggleMenu={toggleMenu} />
          <div className="body-data">
            <Routes>
              <Route exact path="/" element={<Home />} />

              <Route path="/dawah" element={<DawahPage />} />
              <Route path="/halal" element={<HalalPage />} />
              <Route path="/shahadah" element={<Shahadah />} />
              <Route
                path="/japan-dawah-centre"
                element={<JapanDawahCenter />}
              />
              <Route path="/mosques" element={<Mosques />} />
              <Route path="/masjid-al-dawah" element={<MasjidAlDawah />} />
              <Route path="/login" element={<Login />} />
              <Route path="/posts" element={<Posts />} />
              <Route exact path="/post/:id" element={<PostDetail />} />
              <Route
                exact
                path="/category/:categoryName"
                element={<Category />}
              />
              <Route path="/events" element={<Events />} />

              <Route path="/imperson-shahadah" element={<ImpersonShahadah />} />
              <Route
                path="/admin"
                element={
                  <AuthenticatedRoute>
                    <AdminPanel />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/create-post"
                element={
                  <AuthenticatedRoute>
                    <CreatePost />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/post/:id/edit"
                element={
                  <AuthenticatedRoute>
                    <Edit />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/create-event"
                element={
                  <AuthenticatedRoute>
                    <CreateEvents />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/category"
                element={
                  <AuthenticatedRoute>
                    <Categories />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/event/:id/edit"
                element={
                  <AuthenticatedRoute>
                    <EventEdit />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/instagram-token"
                element={
                  <AuthenticatedRoute>
                    <ChangeToken />
                  </AuthenticatedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </main>
      </AuthProvider>
    </Fragment>
  );
}
