export function showShortDesc(description, substring = 60) {
  const shortDes =
    description
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")

      // .replace(/<\/p>/g, "<br/>")
      .substring(0, substring) + "...";
  return shortDes;
}
