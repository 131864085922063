import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

export default function DawahPage() {
  return (
    <div>
      <SectionHeader>
        <h1 className="title">ジャパン ダアワ センターの活動 </h1>
      </SectionHeader>
      <section className="president-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 row align-center">
              <div className="text-wrapper">
                <img
                  className="president"
                  src="./japan-works-page/president.png"
                  alt="Japan Dawah Centre"
                />
                <h2 className="heading">ネットワーク</h2>
                <h3 className="pre-heading">
                  マレーシア第4首相から手紙を頂きました
                </h3>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img
                className="president-letter"
                src="./japan-works-page/president-letter.jpg"
                alt="Japan Dawah Centre"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-3 vd-icon col-xs-6">
              <img alt="japan dawah centre" src="./images/youtube.jpg" />
            </div>
            <div className="col-md-3 vd-icon col-xs-6">
              <img alt="japan dawah centre" src="./images/zoom.jpg" />
            </div>
            <div className="col-md-6 row align-center">
              <div className="text-wrapper">
                <h2 className="heading">勉強会</h2>
                <p className="mb-30">
                  インターン生とのクルアーンの真のメッセージを読み解く勉強会を開催させて頂きました。
                </p>
                <Link to="/events" className="btn invert">
                  イベントを表示
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-wrapper">
                <h3 className="heading">毎週日曜日に授業が開催されています</h3>
                <div className="points">
                  <p>
                    ビギナーからでもイスラームについて学べる様々な授業を用意しております！
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 dawah">
              <img
                alt="japan dawah centre"
                className="dawah-image"
                src="./japan-works-page/Picture4.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="cards">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img
                alt="japan dawah centre"
                className="icon"
                src="./images/iqra-class.jpg"
              />
              <h3 className="iqra">イクラ(アラビア語の音読)クラス</h3>
              <p className="text">
                興味のある方には、クルアーンをアラビア語で読めるように学びます。イクラは、文字と発音などの基本的なものからクルアーンを読む方法までを学ぶ本です。
              </p>
            </div>
            <div className="col-md-4">
              <img
                alt="japan dawah centre"
                className="icon"
                src="./images/arabic-lang.jpg"
              />
              <h3 className="arabic">アラビア語クラス</h3>
              <p className="text">
                イスラームの言葉であるアラビア語を学びます。
                アラビア語を学ぶ中で、イスラームの教えも同時に学べます。
                先生は日本語が堪能なアラブ人です。
              </p>
            </div>
            <div className="col-md-4">
              <img
                alt="japan dawah centre"
                className="icon"
                src="./images/tadabbur-al-quran.jpg"
              />
              <h3 className="tadabbur">タダッブル クルアーン</h3>
              <p className="text">
                インターン生とアルクルアーンのメッセージを紐解く勉強会を定期的に開催しています。
              </p>
            </div>
            <div className="col-md-4">
              <img
                alt="japan dawah centre"
                className="icon"
                src="./images/eid.jpg"
              />
              <h3 className="events">イード(断食明け)のお祝い</h3>
              <p className="text">
                イスラム教徒間の絆を強めるために、一緒に祈って、食事をします。もちろん、日本人のみなさんが参加することも大歓迎です。
              </p>
            </div>
            <div className="col-md-4 g">
              <img
                alt="japan dawah centre"
                className="icon"
                src="./images/marriage.jpg"
              />
              <h3 className="events">結婚式</h3>
              <p className="text">
                お互いをサポートするという気持ちを持っている結婚希望者なら誰でも参加できます。また、参加者として見学も歓迎しています。
              </p>
            </div>
            <div className="col-md-4">
              <img
                alt="japan dawah centre"
                className="icon"
                src="./images/jummah.jpg"
              />
              <h3 className="events">金曜日礼拝</h3>
              <p className="text">
                {" "}
                イスラム教徒には1日5回イスラム教の祈りが義務付けられています。
                しかし、金曜日の祈りは特別です。
                金曜日の祈りであれ、毎日の5回の祈りであれ、いつでもJDCでの礼拝・見学を歓迎します。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="light-grey">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6">
              <img
                alt="japan dawah centre"
                className="internship-image"
                src="./japan-works-page/vvcc.jpg"
              />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h2 className="heading">インターンシップ</h2>
                <p className="text">
                  高校生や大学生などの学生を対象としたインターンシップも行っています。
                  さまざまな活動を通じて、イスラームの教えを学び、イスラム教徒と交流できる場所を用意しています。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6">
              <img
                alt="japan dawah centre"
                className="hanami-image"
                src="./japan-works-page/hanami.png"
              />
            </div>
            <div className="col-md-6 vertical-center">
              <div className="text-wrapper">
                <h2 className="heading">イベント</h2>
                <h3>花見</h3>
                <p className="text">
                  ジャパンダアワセンターに関係するすべての日本人と外国人が集まります。
                  とても楽しくて面白い時間であり、親睦を深めることができます。
                  ダアワは、イスラームを教えるだけでなく、一緒に楽しんでイスラームの良い面を見せることでもあります。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6 mobile-order-2">
              <div className="text-wrapper">
                <h2 className="heading">交流会、ハラールフード食事会</h2>
                <p>
                  一緒に美味しいハラール料理を味わい、ムスリムとお互いについて話しましょう。
                  そしてムスリムの友達をつくりましょう。
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="./images/iftar.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <h1 className="heading text-center">
            シャハーダ(イスラームへの入信)
          </h1>
          <div className="row align-center">
            <div className="col-md-6 shahadah">
              <img
                alt="japan dawah centre"
                className="shahadah-image"
                src="./japan-works-page/shahadahaPic1.png"
              />
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h3 className="pre-heading">アメリカ人のシャハーダ</h3>
                <p className="text">
                  イスラームに入信したい人向けです。
                  彼らが入信を強制されていないことを確認した後、イスラームの信仰について共有します。
                  私たちがどこから来たのか、誰なのかは関係ありません。
                  私たちはイスラム教徒として、アッラーを信じようとしたすべての人を歓迎します。
                </p>
              </div>
            </div>
          </div>
          <div className="row align-center">
            <div className="col-md-6">
              <h3 className="pre-heading">着物を着てシャハーダ</h3>
              <p className="text">
                イスラームを受け入れることは、あなたの人種を変えることはありません。イスラームはすべての人類のためのものなのです。
              </p>
            </div>

            <div className="col-md-6">
              <img
                alt="japan dawah centre"
                className="kimono-image"
                src="./japan-works-page/kimono1.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="light-grey">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-3 col-d">
                              <img alt="japan dawah centre" className="yamato-image" src="./japan-works-page/yamatotoabu.jpg" />
                              <h3>マンガ「大和とアブ」</h3>
                              <p className="text"> アラビア語の会話を楽しく学ぶために、JDCマレーシアのインターン生が制作した漫画です</p>
                          </div>
                          <div className="col-md-3 col-d">
                              <img alt="japan dawah centre" className="halaltoku-image" src="./japan-works-page/halaltoku.png" />
                              <h3 className="black">シリーズ「ハラールトーク」</h3>
                              <p className="text">3〜5分のシリーズビデオです。ビデオはフェイスブックとインスタグラムで閲覧できます。 ジャパンダアワセンターのインスタグラムのIGTVシリーズで観ることができます。
シリーズ名：ハラールトーク
日本人と外国人の両方ためのものです。</p>
                          </div>
                          <div className="col-md-3 col-d ">
                              <img alt="japan dawah centre" className="kitadab-image" src="./japan-works-page/kitadab.jpg" />
                              <h3 className="black">キット アダブ</h3>
                              <p className="text">キット アダブは、イスラームのマナーについて紹介するシリーズのポスターです。</p>
                          </div>

                          <div className="col-md-3 col-d">
                              <img alt="japan dawah centre" className="recipe-image" src="./japan-works-page/halalrecipe.jpg" />
                              <h3>ハラールレシピ</h3>
                              <p className="text"> このシリーズでは、おいしいハラール日本料理を紹介します。このポスターを参考にして、自分で作ることができます。このシリーズはJDCのインスタグラムのハイライトで見れます。これは日本人と外国人の両方のためのものです。"</p>
                          </div>
                      </div>
                  </div>
              </section> */}
    </div>
  );
}
