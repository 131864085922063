import React, { Component, Fragment, useEffect, useState } from "react";
import { db } from "../../firebase-config";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
} from "@firebase/firestore";
import EventItem from "../../Components/EventItem/EventItem";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import PostLoader from "../../Components/PostLoader/PostLoader";

export default function Events() {
  const [longTermEvent, setLongTermEvent] = useState(null);
  const [oneTimeEvents, setOneTimeEvents] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    // const continousEvents = query(
    //   collection(db, "events"),
    //   where("longTermEvent", "==", true),
    //   orderBy("creationDate", "desc")
    // );
    const oneTimeEvents = query(
      collection(db, "events"),
      // where("longTermEvent", "==", false),
      orderBy("creationDate", "desc")
    );

    const getEvents = async () => {
      // const dataContinous = await getDocs(continousEvents);
      const dataoneTime = await getDocs(oneTimeEvents);
      // setLongTermEvent(
      //   dataContinous.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      // );
      setOneTimeEvents(
        dataoneTime.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      console.log(
        dataoneTime.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };

    getEvents().then(() => {
      setDataLoaded(true);
    });
  }, []);

  return (
    <div>
      <>
        <SectionHeader>
          <h1 className="title">Events</h1>
        </SectionHeader>
        <section className="event-page">
          <div className="container">
            <div className="row">
              {dataLoaded ? (
                oneTimeEvents.map((event, i) => (
                  <EventItem event={event} key={i} />
                ))
              ) : (
                <Fragment>
                  {[1, 2, 3, 4, 5, 6].map((load, id) => (
                    <PostLoader key={id} />
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        </section>
        {/* {longTermEvent && (
          <section className="event-page">
            <div className="event-title-wrapper">
              <h2 className="heading">Repeating Events</h2>
            </div>
            <div className="container">
              <div className="row">
                {longTermEvent.map((event, i) => (
                  <>
                    <EventItem event={event} key={i} />
                  </>
                ))}
              </div>
            </div>
          </section>
        )} */}
      </>
    </div>
  );
}
