import React, { useEffect, useState } from "react";
import { db, storage } from "../../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc, addDoc, collection } from "firebase/firestore";

import Resizer from "react-image-file-resizer";
import "./create-post.styles.css";
import { useNavigate } from "react-router-dom";

export default function CreatePost() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [postCategory, setPostCategory] = useState("");
  const [embedLink, setEmbedLink] = useState("");
  const [hasImage, setHasImage] = useState(true);
  const [imageUrls, setImageUrls] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  useEffect(() => {
    console.log(image);
  }, [image]);
  function handleImage(e) {
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      for (let i = 0; i < e.target.files.length; i++) {
        try {
          Resizer.imageFileResizer(
            e.target.files[i],
            1000,
            1000,
            "JPEG",
            100,
            0,
            (uri) => {
              setImage((prevState) => [...prevState, uri]);
            },
            "file"
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    setButtonText("Submiting");
    const addImage = () => {
      return image.map((img) => {
        const storageRef = ref(storage, `images/posts/${img.name}`);
        const uploadTask = uploadBytes(storageRef, img);
        return uploadTask;
      });
    };
    const addPost = async (obj) => {
      const postRef = collection(db, "posts");
      await addDoc(postRef, obj);
    };
    const postDesc = "<p>" + description.replace(/\n/g, "</p>\n<p>") + "</p>";
    let uploadTasks = addImage();
    uploadTasks.forEach((task) => task.then(/*change state*/));
    Promise.all(uploadTasks).then((results) => {
      let urls = results.map((result) => getDownloadURL(result.ref));
      Promise.all(urls)
        .then((urls) => {
          console.log(urls);
          addPost({
            title: title,
            description: postDesc,
            hasImage: hasImage,
            image: urls,
            videoLink: embedLink,
            category: postCategory,
            date: new Date(),
            thumbnail: !hasImage && urls,
          });
        })
        .then(() => {
          setButtonText("Submitted!!");

          navigate("/posts");
        });
    });
  }
  function addCategory(e) {
    e.preventDefault();
    const OldCategory = category.category;
    console.log(OldCategory);
    OldCategory.push(newCategory);
    const addNewCat = async () => {
      const categoryList = doc(db, "categories", "category");
      await updateDoc(categoryList, { category: OldCategory });
    };
    addNewCat();
  }
  useEffect(() => {
    const categoryList = doc(db, "categories", "category");
    const getCategory = async () => {
      const fetchedCategory = await getDoc(categoryList);
      if (fetchedCategory.exists()) {
        setCategory(fetchedCategory.data());
      }
    };
    getCategory();
  }, []);

  const categoryArr = category.category;
  return (
    <div className="create-post">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="form">
                <h1 className="heading">Create a new Post</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="category">Category:</label>
                    <select
                      name=""
                      id=""
                      defaultValue="selectCategory"
                      onChange={(e) => {
                        setPostCategory(e.target.value);
                      }}
                    >
                      <option value="selectCategory" disabled>
                        Select a Category
                      </option>
                      {categoryArr &&
                        categoryArr.map((cat, i) => (
                          <option value={cat} key={i}>
                            {cat}
                          </option>
                        ))}
                      {newCategory && (
                        <option value={newCategory}>{newCategory}</option>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="text-area">Description</label>
                    <textarea
                      name="text-area"
                      placeholder="Add a Description"
                      id=""
                      cols="30"
                      rows="10"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="has Image">Use Image</label>
                    <label className="check-box">
                      <input
                        type="checkbox"
                        name="useImage"
                        id="useImage"
                        defaultChecked={hasImage}
                        onChange={(e) => setHasImage(!hasImage)}
                      />
                      <span className="check-box-slider"></span>
                    </label>
                  </div>
                  {hasImage && (
                    <div className="form-group upload-image">
                      <label htmlFor="image">Upload an Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        name="image"
                        alt="Post Image"
                        multiple
                        onChange={handleImage}
                      />
                    </div>
                  )}
                  {!hasImage && (
                    <>
                      <div className="form-group upload-image">
                        <label htmlFor="image">Add a Thumbnail</label>
                        <input
                          type="file"
                          accept="image/*"
                          name="thumbnailImage"
                          alt="Thumbnail Image"
                          onChange={handleImage}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="youtube-link">Embed Link</label>
                        <input
                          type="text"
                          name="youtube-link"
                          id=""
                          placeholder="Enter Embed Link"
                          onChange={(e) => {
                            setEmbedLink(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <button type="submit" className="form-btn">
                      {buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-3">
              <div className="new-category">
                <form onSubmit={addCategory}>
                  <label htmlFor="add new Category">Add Category</label>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="category name"
                      onChange={(e) => {
                        setNewCategory(e.target.value);
                      }}
                    />
                  </div>
                  <button type="submit" className="form-btn">
                    Add
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
