import React from "react";
import styles from "./PostLoader.module.css";
export default function PostLoader() {
  return (
    <div className="col-md-4 col-xs-6">
      <div className={styles.post_loader}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.category}></div>
          <div className={styles.title}></div>
          <div className={styles.description}></div>
          <div className={styles.description}></div>
          <div className={styles.description}></div>
        </div>
      </div>
    </div>
  );
}
