import React from "react";
import styles from "./OurAim.module.css";
export default function OurAim() {
  return (
    <section className="section-50">
      <div className="container">
        <div className={styles.box}>
          <div className="row align-center">
            <div className="col-md-6">
              <div className="text-wrapper">
                <h2 className="heading">私たちの狙い</h2>
                <h3 className="pre-heading">
                  「総合センター」を構築するには：
                </h3>
                <p>
                  "日本にあるほとんどのモスクは、海外の文化をそのまま採用して建てられました。
                  これにより、イスラームは日本人とは「無関係」と見なされるようになりました。
                </p>
                <p>
                  これらのモスクは、外国人のイスラム教徒の礼拝所として使用されています。
                  日本の建築と文化が調和したモスクを通じて、この考え方を変えていきます。
                  日本人がモスクで関われる機会を増やすことが私たちの目標です。"
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.aim}>
                <div>
                  <img src={"/images/mosque1.png"} alt="japan dawah centre" />
                  <p>イスラームの活動のための総合センター</p>
                </div>
                <div>
                  <img src={"/images/dawah.png"} alt="japan dawah centre" />
                  <p>ダアワ活動</p>
                </div>
                <div>
                  <img src={"/images/salat.png"} alt="japan dawah centre" />
                  <p>福祉・コミュニティ活動</p>
                </div>
                <div>
                  <img src={"/images/quran-1.png"} alt="japan dawah centre" />
                  <p>イスラーム知識の提供</p>
                </div>
                <div>
                  <img src={"/images/ornament.png"} alt="japan dawah centre" />
                  <p>地域住民に向けた商品販売</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
