import React, { useContext, useEffect, useState } from "react";
import { auth } from "../../firebase-config";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import "./login.styles.css";
import { AuthContext } from "../../Context/Auth";

export default function Login() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorCode, setErrorCode] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [loggedIn, setLoggedIn] = useState(null);
  useEffect(() => {
    if (currentUser) {
      navigate("/admin");
    }
  }, [currentUser]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        setLoggedIn(user);
        navigate("/admin");
      })
      .catch((error) => {
        setErrorCode(error.code);
        setErrorMessage(error.message);
      });
  };

  return (
    <div className="login">
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <h4 className="pre-heading">Login</h4>
          <input
            required
            type="text"
            name="Email"
            placeholder="email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <input
            required
            type="password"
            name="Password"
            placeholder="password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <input type="submit" value="Submit" className="btn invert" />
        </form>
        {errorCode && <p className="error">{errorMessage}</p>}

        {currentUser && (
          <div>
            <button className="btn invert" onClick={() => auth.signOut()}>
              signout
            </button>
            <p>{currentUser.email}</p>
          </div>
        )}
      </div>
    </div>
  );
}
