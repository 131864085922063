import React, { useEffect, useState } from "react";
import MosquesCard from "./MosquesCard.jsx";
import { cities, mosqueCities, mosques } from "./mosqueData";
import SectionHeader from "../../Components/SectionHeader/SectionHeader.jsx";
import Filters from "../../Components/Filters/Filters.jsx";

export default function Mosques() {
  const [accordionMenu, setAccordionMenu] = useState(0);
  const [mosquesArr, setMosquesArr] = useState(mosques);
  const [activecity, setActiveCity] = useState("all");
  const openAccordion = (index) => {
    if (accordionMenu === index) {
      return setAccordionMenu(null);
    }
    setAccordionMenu(index);
  };

  function handlePlaceChange(place) {
    setActiveCity(place);
    if (place == "all") {
      setMosquesArr(mosques);
    } else {
      const filteredMosques = mosques.filter((mosques) => {
        return mosques.city === place;
      });
      setMosquesArr(filteredMosques);
    }
  }
  useEffect(() => {
    console.log(mosquesArr);
  }, [mosquesArr]);
  return (
    <div>
      <SectionHeader>
        <h1 className="title">Mosques in Japan</h1>
      </SectionHeader>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Filters
                title={"City"}
                current={activecity}
                handleTabChange={handlePlaceChange}
                arr={cities}
              />
            </div>
            <div className="col-md-10">
              <div className="row mosque-data">
                <MosquesCard mosque={mosquesArr} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
