import React from "react";
import styles from "./OurFocus.module.css";
export default function OurFocus() {
  const focuses = [
    {
      image: "/images/muslim-man.jpg",
      title: "若い日本人",
      description: `一般的に日本人の若者は、神が何であるかについて明確な答えを持っておらず、神を信じていない人さえいます。
            私たちはこれらの疑念と疑問を晴らす答えを提示いたします。`,
    },
    {
      image: "/images/meeting.png",
      title: "実業家",
      description: `ハラール産業は世界中で凄まじい勢いで成長しています。
            日本でイスラム教徒の人口増加と多岐に渡る分野への輸出可能性を考慮すると、日本が参入しない理由はありません。`,
    },
    {
      image: "/images/class-live.jpg",
      title: "学生",
      description: `私たちは常にイスラームの美しさについて興味を持つ様々な学生を受け入れています。
            希望すれば、ここでインターン生として携わることもできます。
            学生と連携したイベントもあります。`,
    },
    {
      image: "/images/course.png",
      title: "コミュニティ",
      description: `イスラームは信念や思いやり、死について教えています。昨今、様々な問題を抱える日本がこれらを求めていると感じます。
            このイスラームの見解を共有できれば幸いです。`,
    },
  ];
  return (
    <section>
      <div className="container">
        <h2 className="heading">Our Focus</h2>
        <div className="row">
          {focuses.map((focus, id) => (
            <div className="col-md-3" key={id}>
              <div className={styles.card}>
                <div>
                  <img src={focus.image} alt={focus.description} />
                </div>
                <h4>{focus.title}</h4>
                <p>{focus.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
