import React, { useEffect, useState } from "react";
import "./postDetail.styles.css";
import { db } from "../../firebase-config";
import {
  doc,
  getDoc,
  query,
  collection,
  orderBy,
  getDocs,
} from "@firebase/firestore";

import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";
import { useParams } from "react-router-dom";

export default function PostDetail() {
  const [posts, setPosts] = useState([]);
  const [postIndex, setPostIndex] = useState([]);
  const [postsID, setPostsID] = useState([]);
  const [nextPost, setNextPost] = useState("");
  const [prevPost, setPrevPost] = useState("");
  const postId = useParams().id;
  useEffect(() => {
    const postIdCollection = query(
      collection(db, "posts"),
      orderBy("date", "desc")
    );
    const getPostId = async () => {
      const data = await getDocs(postIdCollection);
      setPostIndex(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      postIndex.map((postI) =>
        setPostsID((prevState) => [...prevState, postI.id])
      );
    };
    getPostId().then(() => {
      setNextPost(postsID[postsID.indexOf(postId) + 1]);
      setPrevPost(postsID[postsID.indexOf(postId) - 1]);
    });

    const postsCollection = doc(db, "posts", postId);
    const getPosts = async () => {
      const data = await getDoc(postsCollection);
      if (data.exists()) {
        console.log(data.data());
        setPosts(data.data());
      }
    };
    getPosts();
  }, []);

  return (
    <div
      className={
        posts.videoLink && posts.videoLink.includes("instagram")
          ? "instagram-post post-detail"
          : "post-detail"
      }
    >
      <div className="row next-prev-post">
        {prevPost && (
          <a className=" prev" href={"/post/" + prevPost}>
            Previous Post
          </a>
        )}
        {nextPost && (
          <a className="next" href={"/post/" + nextPost}>
            Next Post
          </a>
        )}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 post-media">
            <div className="media-wrapper">
              {posts.hasImage ? (
                <>
                  {posts.image.length > 1 ? (
                    <ReactSlidy infiniteLoop itemsToPreload={5}>
                      {posts.image.map((img, i) => (
                        <img src={img} key={i} alt="" />
                      ))}
                    </ReactSlidy>
                  ) : (
                    <img src={posts.image[0]} alt="" />
                  )}
                </>
              ) : (
                <>
                  {posts.videoLink && (
                    <>
                      {posts.videoLink.includes("instagram") ? (
                        <iframe
                          src={posts.videoLink + "embed"}
                          title="Instagram Embed"
                        ></iframe>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: posts.videoLink,
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 post-contents">
            <div className="post-detail-content">
              <h2>{posts.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: posts.description }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
