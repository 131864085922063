import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";

export default function ImpersonShahadah() {
  return (
    <>
      <SectionHeader>
        <h1 className="heading">オンライン入信式のご案内</h1>

        <p>
          こちらはダアワセンターに訪れることが出来ない人でもイスラム教に入信することが出来るサービスです。
        </p>
        <p>
          オンラインでムスリムになるためには3つのステップがあり、その全ては5分で終わります。
        </p>
      </SectionHeader>
      <section className="light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">ステップ1 : 意思の確認</h2>
              <p>
                <strong>
                  ムスリムになるための最初のステップは、『意志の確認』です。
                </strong>{" "}
                あなたが唯一神アッラーを心から信じる事、かれ意外に崇拝に値するものはないという事、そして預言者ムハンマドが最後の預言者である事を信じる意志を確認してください。
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">ステップ2: シャハーダ（証言）</h2>
              <p>
                二つ目のステップはシャハーダ（証言）です。
                シャハーダとは、『私はアッラー以外に神はなく、預言者ムハンマドは彼の使徒である事を証言します』と口に出す事で、あなたの意志決定を確認する行為です。
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="pre-heading">
                手順の説明:
                それではいよいよムスリムになるためにシャハーダを行ないましょう。次の二つの文章を声に出して呼んでみてください、（日本語、アラビア語両方あります）
              </h2>
              <h2 className="pre-heading">
                以下の二つの文章を読むことであなたはムスリムになります。そしてその後に最後のステップを始めましょう。
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">証言：1</h2>
              <h2 className="pre-heading">
                『私はアッラー以外に神はいない事、また預言者ムハンマドは最後の使徒である事を証言します。』
              </h2>
              <h2 className="heading mt-20">証言：2</h2>
              <h2 className="pre-heading">
                『アシュハドゥ　アッラー　イラーハ　イッラッラー
                ワ　アシュハドゥ　アンナ　ムハンマダン　ラスｰルッラー』
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">最後のステップ: 連絡先のご登録</h2>
              <a
                href="https://forms.gle/4SxEE9wJE63aJe7L9"
                className="btn invert "
                target="_blank"
                rel="noreferrer"
              >
                連絡先を登録
              </a>
              <p className="mt-30">
                おめでとうございます！これであなたは晴れてムスリムになることが出来ました！そこで以下の登録フォームの中にあなたの名前、メールアドレス、住所をご記入ください。（お書きになった住所の元にクルアーンをお送りいたします。）ご登録されたメールアドレスに認証メッセージをお送りいたします。
                すべての欄に記載を完了した後、登録されたメールアドレスの方に礼拝の方法を分かりやすく書いた資料のPDFとデジタル版のクルアーンをお送りいたします。
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
