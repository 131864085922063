import React from "react";
import "./Home.styles.css";
import FacebookEmbed from "../../Components/Facebook/FacebookEmbed";
import Instafeed from "../../Components/Instafeed/Instafeed";
import Hero from "../../Components/Home/Hero/Hero";
import Intro from "../../Components/Home/Intro/Intro";
import RecentPosts from "../../Components/Home/RecentPosts/RecentPosts";
import OurFocus from "../../Components/Home/OurFocus/OurFocus";
import OurAim from "../../Components/Home/OurAim/OurAim";
import OurMission from "../../Components/Home/OurMission/OurMission";

export default function Home() {
  return (
    <div>
      <Hero />
      <Intro />
      <OurMission />
      <RecentPosts />
      <OurAim />
      <OurFocus />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="heading">銀行口座情報</h2>
            </div>
            <div className="col-md-6 row">
              <div className="bank-card">
                <img src="./images/cimb-bank.png" alt="" />
                <div className="bank-name">
                  <h4 className="pre-heading">Malaysia Account</h4>
                  <p>
                    <span className="donation-type">Zakat</span>
                    <span className="don">Infaq</span>
                  </p>
                </div>
                <p className="account-number">8603519647</p>
                <p>(DidBMT ENTERPRISE)</p>
                <p>
                  Please Write down the Purpose of transaction as <br />
                  <strong>(Masjid Dawah/ Infaq/ Zakat/Waqaf)</strong>
                </p>
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="bank-card">
                <img src="./images/smbc.png" alt="" />
                <div className="bank-name">
                  <h4 className="pre-heading">Japan Account</h4>
                  <p>
                    <span className="donation-type">Masjid Only</span>
                  </p>
                </div>
                <p className="bank-d">
                  SMBC (Mitsui Sumitomo Bank) Ippan Shadan Houjin{" "}
                </p>
                <p className="det">Japan Dawah Centre</p>
                <p>
                  Branch <strong>Kawachinagano</strong> (164) <br />
                  Futsu Account No: <strong>1679638</strong>
                </p>
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="bank-card">
                <img src="./images/mufg.png" alt="" />
                <div className="bank-name">
                  <h4 className="pre-heading">Japan Account</h4>
                  <p>
                    <span className="donation-type">Zakat</span>{" "}
                    <span className="don">INFAQ only</span>
                  </p>
                </div>
                <p className="bank-d">
                  MUFG (Mitsubishi UFJ Bank) Ippan Shadan Houjin{" "}
                </p>
                <p className="det">Japan Muslim Foundation</p>
                <p>
                  Branch <strong>Kawachinagano</strong> (247) <br /> Account No:{" "}
                  <strong>0397331</strong>
                </p>
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="bank-card">
                <img src="./images/jp-bank.png" alt="" />
                <div className="bank-name">
                  <h4 className="pre-heading">Japan Account</h4>
                  <p>
                    <span className="donation-type">Zakat</span>{" "}
                    <span className="don">INFAQ only</span>
                  </p>
                </div>
                <p className="bank-d">
                  JP Bank (Japan Post Bank)
                  <br />
                  Normal Deposit Account{" "}
                </p>
                <p className="det">Japan Muslim Foundation</p>
                <p>
                  Branch <strong>408</strong>
                  <br />
                  Futsu Account No: <strong>4508081</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 facebook-container">
              <h2 className="heading">フェイスブック</h2>
              <div className="fb-embed">
                <FacebookEmbed />
              </div>
            </div>
            <div className="col-md-6">{/* <Instafeed /> */}</div>
          </div>
        </div>
      </section>
    </div>
  );
}
