import { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "@firebase/firestore";
import { useSearchParams } from "react-router-dom";
export default function useLoadPosts(limitPost = false) {
  const [posts, setPosts] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastVisible, setLastVisible] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const catParams = searchParams.get("category")
    ? searchParams.get("category")
    : "all";
  useEffect(() => {
    if (!posts.length) {
      loadPostData(catParams);
    }
  }, []);
  const loadPostData = (category) => {
    setPosts([]);
    setDataLoaded(false);
    setCategoryName(category);
    if (window.location.pathname === "/posts") {
      setSearchParams({ category: category });
    }

    let postsCollection;
    if (category === "all") {
      postsCollection = query(
        collection(db, "posts"),
        orderBy("date", "desc"),
        limit(limitPost || 25)
      );
    } else {
      postsCollection = query(
        collection(db, "posts"),
        where("category", "===", category),
        orderBy("date", "desc")
      );
    }
    getPosts(postsCollection).then(() => {
      setDataLoaded(true);
    });
  };

  const loadMoreData = () => {
    if (lastVisible !== false) {
      const postsCollection = query(
        collection(db, "posts"),
        orderBy("date", "desc"),
        startAfter(lastVisible || 25),
        limit(25)
      );

      getPosts(postsCollection).then(() => {
        setDataLoaded(true);
      });
    }
  };
  const getPosts = async (postQuery) => {
    const data = await getDocs(postQuery);
    setPosts((prevState) => {
      return [
        ...prevState,
        ...data.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      ];
    });
    setLastVisible(data.docs[data.docs.length - 1]);
  };
  return {
    posts,
    dataLoaded,
    lastVisible,
    loadMoreData,
    loadPostData,
    categoryName,
  };
}
