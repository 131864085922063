import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
export default function Footer() {
  return (
    <Fragment>
      <footer>
        <div className="container">
          <div className={"row " + styles.wrapper}>
            <div className="col-md-4">
              <div className={styles.logo}>
                <img src="/images/logo-icon.png" alt="Japan Dawah Centre" />
              </div>
              <div className={styles.social}>
                <a
                  href="https://www.facebook.com/JapanDawahCentreMedia/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/japan.dawah.centre.v1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCVJzq7xVCBOXg-Q21sl8OzA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  href="https://line.me/ti/p/8Q7f99UFJ7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-line"></i>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <p className={styles.link_head}>Links</p>
                <ul>
                  <li>
                    <Link to={"/posts"}>ブログ</Link>
                  </li>
                  <li>
                    <Link to="/events">イベント</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.nippon-muslim.co.jp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      日本ムスリム株式会社
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://forms.gle/6e9WEmzCvRdmBGAf9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      お問い合わせ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <p className={styles.link_head}>Contact</p>
                <p>大阪府大阪市住吉区山之内3丁目2-10</p>
                <p>
                  <a href="mailto:japandawahcentre@gmail.com">
                    Japandawahcentre@gmail.com
                  </a>
                </p>
                <p>
                  <a href="tel:06-6629-8210">06-6629-8210</a>
                </p>
                <p>
                  <a href="tel:+81 90-6051-1369">+81 90-6051-1369</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={styles.bottom}>
        Copyright @ {new Date().getFullYear()} | Designed by{" "}
        <a href="https://www.kokorocode.com" target="_blank" rel="noreferrer">
          Kokorocode
        </a>
      </div>
    </Fragment>
  );
}
