export const mosqueCities = [
  {
    city: "Hokkaido",
    mosque: [
      {
        image: "/images/mosques/sapporo-masjid.JPG",
        mosqueName: "Sopporo Masjid",
        mosqueAddress:
          "Kita 14 jo Nishi 3 Chome 1-10,Kitaku,Sapporo,Hokkaido-Japan",
        phoneNumber: "011-709-5008",
      },
      {
        image: "/images/mosques/masjid-al-noor.jpg",
        mosqueName: "Masjid Al-Noor",
        mosqueAddress: "Hokkaido Otaru-shi Inaho 5-4-27",
        phoneNumber: "090-7011-8005 / 090-2071-5688",
      },
      {
        image: "/images/mosques/muroran-mosuqe.jpg",
        mosqueName: "Muroran Mosque",
        mosqueAddress: "5-18-6-1F Takasago-cho, Muroran-shi, Hokkaido",
        phoneNumber: "0135363632",
      },
      {
        image: "/images/mosques/1.jpg",
        mosqueName: "Chitose Outlet Mall RERA",
        mosqueAddress: "1-2-1 , Kashiwadai Minami, Shitose-shi, Hokkaido",
        phoneNumber: "0135363632",
      },
    ],
  },
  {
    city: "Iwate",
    mosque: [
      {
        image: "/images/mosques/morioka-masjid.jpg",
        mosqueName: "Morioka Masjid",
        mosqueAddress: "2-22 Abetate-cho, Morioka-shi, Iwate",
        website: "http://moriokamuslim.blogspot.jp/",
      },
    ],
  },
  {
    city: "Fukushima",
    mosque: [
      {
        image: "/images/mosques/iwaki-masjid.jpg",
        mosqueName: "Iwaki Masjid",
        mosqueAddress:
          "176-1 Miyanoshita Shimokawa , Izumi-cho , Iwaki-shi , Fukushima",
      },
    ],
  },
  {
    city: "Miyagi",
    mosque: [
      {
        image: "/images/mosques/islamic-cultural-centre-of-sendai.jpg",
        mosqueName: "Islamic Cultural Centre of Sendai",
        mosqueAddress:
          "17-24, hachiman 7-chome, Aoba-ku, Sendai, Miyagi, Japan.",
        website: "http://iccsendai.org/web/en/",
      },
    ],
  },
  {
    city: "Tokyo",
    mosque: [
      {
        image: "/images/mosques/tokyo-camii.jpg",
        mosqueName: "Tokyo Camii",
        mosqueAddress: "1-19 Oyama-cho, Shibuya-ku, Tokyo 151-0065, JAPAN",
        website: "http://tokyocamii.org/",
      },
      {
        image: "/images/mosques/as-salaam-masjid.jpg",
        mosqueName: "As-Salaam Masjid",
        mosqueAddress: "4-6-7 Taito Taito-ku Tokyo 110-0016",
        website: "http://assalaamfoundation.org/",
      },
      {
        image: "/images/mosques/kamata-masjid.jpg",
        mosqueName: "Kamata Masjid",
        mosqueAddress: "5-1-2 Kamata, Ota-ku, Tokyo",
        website: "http://kamatamasjid.com",
      },
      {
        image: "/images/mosques/otsuka-masjid.jpg",
        mosqueName: "Otsuka Masjid",
        mosqueAddress: "3-42-7 Minami Otsuka, Toshima-ku, Tokyo",
        website: "http://www.islam.or.jp",
      },
      {
        image: "/images/mosques/al-tawheed-mosque.jpg",
        mosqueName: "Al-Tawheed Mosque",
        mosqueAddress: "36-6 Hiraoka-cho Hachioji-shi Tokyo",
        website: "http://www.geocities.jp/masjid_al_tawheed/",
      },
    ],
  },
  {
    city: "Chiba",
    mosque: [
      {
        image: "/images/mosques/hira-masjid.jpg",
        mosqueName: "Hira Masjid",
        mosqueAddress: "3-3-19 Gyotoku Ekimae, Ichikawa-shi, Chiba",
        website: "http://www.hiramasjid.org/",
      },
    ],
  },
  {
    city: "Kanagawa",
    mosque: [
      {
        image: "/images/mosques/jame-masjid-yokohama.jpg",
        mosqueName: "Ja’me Masjid Yokohama",
        mosqueAddress: "1-31-13 Hayabuchi, Tsuzuki-ku, Yokohama-city, Kanagawa",
        website: "http://www.masjid-yokohama.jp",
      },
      {
        image: "/images/mosques/ebina-mosque.jpg",
        mosqueName: "Ebina Mosque",
        mosqueAddress: "3-12-1 Kamigo, Ebina-shi, Kanagawa",
        website: "https://www.facebook.com/pages/Ebina-Mosque/352827714734696",
      },
    ],
  },
  {
    city: "Nagano",
    mosque: [
      {
        image: "/images/mosques/bilal-mosque.jpg",
        mosqueName: "Bilal Mosque",
        mosqueAddress: "1-31-13 Hayabuchi, Tsuzuki-ku, Yokohama-city, Kanagawa",
        website: "http://www.masjid-yokohama.jp",
      },
      {
        image: "/images/mosques/ebina-mosque.jpg",
        mosqueName: "Ebina Mosque",
        mosqueAddress: "6830-4 Minamijyo, Sakaki-machi, Hanishina-gun, Nagano",
      },
    ],
  },
  {
    city: "Shizuoka",
    mosque: [
      {
        image: "/images/mosques/fuji-masjid.jpg",
        mosqueName: "Fuji Masjid",
        mosqueAddress: "2561-29 Nakazato, Fuji-shi, Shizuoka",
      },
      {
        image: "/images/mosques/mohammadi-mosque.jpg",
        mosqueName: "Mohammadi Mosque",
        mosqueAddress: "161-2 Terawaki cho, Minami-ku, Hamamatsu-shi, Shizuoka",
        website: "http://www.hamamatsumosque.com",
      },
    ],
  },
  {
    city: "Aichi",
    mosque: [
      {
        image: "/images/mosques/nagoya-mosque.jpg",
        mosqueName: "Nagoya Mosque",
        mosqueAddress: "2-26-7 Honjindori, Nakamura-ku, Nagoya, Aichi",
        website: "http://en.nagoyamosque.com",
      },
    ],
  },
  {
    city: "Kyoto",
    mosque: [
      {
        image: "/images/mosques/kyoto-masjid.jpg",
        mosqueName: "Kyoto Masjid",
        mosqueAddress:
          "Miyagaki-cho92, Kamigyo-ku Kawaramachi, Kyoto-shi, Kyotoi",
        website: "http://www.islamjapan.net/ibc/index.html",
      },
    ],
  },
  {
    city: "Osaka",
    mosque: [
      {
        image: "/images/mosques/osaka-masjid.jpg",
        mosqueName: "Osaka Masjid",
        mosqueAddress: "4-12-16 Owada Nishi Yodogawa-ku Osaka",
        website: "http://osakamasjid.info",
      },
      {
        image: "/images/mosques/osaka-ibaraki-mosque.jpg",
        mosqueName: "Osaka Ibaraki Mosque",
        mosqueAddress: "4-6-13, Toyokawa, Ibaraki City , Osaka",
        website: "http://osakamosque.org",
      },
      {
        image: "/images/mosques/masjid-istiqlal-osaka.jpg",
        mosqueName: "Masjid Istiqlal Osaka",
        mosqueAddress:
          "1 Chome-2-10 Nakahiraki, Nishinari Ward, Osaka, 557-0022",
        website: "http://www.masjidistiqlalosaka.com/",
      },
      {
        image: "/images/mosques/japan-dawah-centre.jpg",
        mosqueName: "Japan Da'wah Centre / Masjid Al Dawah",
        mosqueAddress:
          "3 Chrome-2-10 Yamanouchi, Sumiyoshi Ward, Osaka, 558-0023",
        website: "http://www.japandawah.com/",
      },
    ],
  },
  {
    city: "Hyogo",
    mosque: [
      {
        image: "/images/mosques/kobe-muslim-mosque.jpg",
        mosqueName: "Kobe Muslim Mosque",
        mosqueAddress: "-25-14 Nakayamate Dori, Chuo-ku, Kobe-shi, Hyogo",
        website: "http://kobe-muslim-mosque.com",
      },
    ],
  },
  {
    city: "Okayama",
    mosque: [
      {
        image: "/images/mosques/okayama-islamic-center.jpg",
        mosqueName: "Okayama Islamic Center",
        mosqueAddress: "2-1-7 Tsushima Higashi, Kita-ku, Okayama-shi, Okayama",
        website:
          "https://www.facebook.com/pages/Okayama-Islamic-Centre/123860261139298",
      },
    ],
  },
  {
    city: "Hiroshima",
    mosque: [
      {
        image: "/images/mosques/higashi-hiroshima-masjid.jpg",
        mosqueName: "Higashi Hiroshima Masjid",
        mosqueAddress:
          "2786-1, Taguchi, Saijyou-cho, Higashihiroshima-shi, Hiroshima",
        website: "https://www.hiroshima-icc.org",
      },
    ],
  },
  {
    city: "Ehime",
    mosque: [
      {
        image: "/images/mosques/niihama-masjid.jpg",
        mosqueName: "Niihama Masjid",
        mosqueAddress: "2-2-43 Ikku-cho, Niihama-shi, Ehime",
        website:
          "https://www.facebook.com/pages/新居浜マスジド/168623866678430",
      },
    ],
  },
  {
    city: "Fukuoka",
    mosque: [
      {
        image: "/images/mosques/fukuoka-masjid.jpg",
        mosqueName: "Fukuoka Masjid",
        mosqueAddress: "3-2-18 Hakozaki , Higashi-ku, Fukuoka-shi, Fukuoka",
        website: "http://www.fukuokamasjid.org",
      },
    ],
  },
  {
    city: "Oita",
    mosque: [
      {
        image: "/images/mosques/beppu-masjid.jpg",
        mosqueName: "Beppu Masjid",
        mosqueAddress: "10-19 Wakakusa-cho, Beppu-shi, Oita",
      },
    ],
  },
  {
    city: "Kumamoto",
    mosque: [
      {
        image: "/images/mosques/kumamoto-islamic-center.jpg",
        mosqueName: "Kumamoto Islamic Center",
        mosqueAddress: "5-5-2, Kurokammi, chuou-ku, Kumamoto-shi, Kumamoto",
        website: "http://www.kicjapan.com/",
      },
    ],
  },
  {
    city: "Kagoshima",
    mosque: [
      {
        image: "/images/mosques/kagoshima-masjid.jpg",
        mosqueName: "Kagoshima Masjid",
        mosqueAddress: "84-21, Masago-cho, Kagoshima",
        website: "http://www.sicc-kagoshima.org",
      },
    ],
  },
  {
    city: "Okinawa",
    mosque: [
      {
        image: "/images/mosques/kokinawa-masjid.jpg",
        mosqueName: "Kokinawa Masjid",
        mosqueAddress: "193-78 Uehara, Nishihara-cho, Nakagami-gun, Okinawa",
      },
    ],
  },
];

export const mosques = [
  {
    image: "/images/mosques/sapporo-masjid.JPG",
    mosqueName: "Sopporo Masjid",
    mosqueAddress:
      "Kita 14 jo Nishi 3 Chome 1-10,Kitaku,Sapporo,Hokkaido-Japan",
    phoneNumber: "011-709-5008",
    city: "Hokkaido",
  },
  {
    image: "/images/mosques/masjid-al-noor.jpg",
    mosqueName: "Masjid Al-Noor",
    mosqueAddress: "Hokkaido Otaru-shi Inaho 5-4-27",
    phoneNumber: "090-7011-8005 / 090-2071-5688",
    city: "Hokkaido",
  },
  {
    image: "/images/mosques/muroran-mosuqe.jpg",
    mosqueName: "Muroran Mosque",
    mosqueAddress: "5-18-6-1F Takasago-cho, Muroran-shi, Hokkaido",
    phoneNumber: "0135363632",
    city: "Hokkaido",
  },
  {
    image: "/images/mosques/1.jpg",
    mosqueName: "Chitose Outlet Mall RERA",
    mosqueAddress: "1-2-1 , Kashiwadai Minami, Shitose-shi, Hokkaido",
    phoneNumber: "0135363632",
    city: "Hokkaido",
  },
  {
    image: "/images/mosques/morioka-masjid.jpg",
    mosqueName: "Morioka Masjid",
    mosqueAddress: "2-22 Abetate-cho, Morioka-shi, Iwate",
    website: "http://moriokamuslim.blogspot.jp/",
    city: "Iwate",
  },
  {
    image: "/images/mosques/iwaki-masjid.jpg",
    mosqueName: "Iwaki Masjid",
    mosqueAddress:
      "176-1 Miyanoshita Shimokawa , Izumi-cho , Iwaki-shi , Fukushima",
    city: "Fukushima",
  },
  {
    image: "/images/mosques/islamic-cultural-centre-of-sendai.jpg",
    mosqueName: "Islamic Cultural Centre of Sendai",
    mosqueAddress: "17-24, hachiman 7-chome, Aoba-ku, Sendai, Miyagi, Japan.",
    website: "http://iccsendai.org/web/en/",
    city: "Miyagi",
  },
  {
    image: "/images/mosques/tokyo-camii.jpg",
    mosqueName: "Tokyo Camii",
    mosqueAddress: "1-19 Oyama-cho, Shibuya-ku, Tokyo 151-0065, JAPAN",
    website: "http://tokyocamii.org/",
    city: "Tokyo",
  },
  {
    image: "/images/mosques/as-salaam-masjid.jpg",
    mosqueName: "As-Salaam Masjid",
    mosqueAddress: "4-6-7 Taito Taito-ku Tokyo 110-0016",
    website: "http://assalaamfoundation.org/",
    city: "Tokyo",
  },
  {
    image: "/images/mosques/kamata-masjid.jpg",
    mosqueName: "Kamata Masjid",
    mosqueAddress: "5-1-2 Kamata, Ota-ku, Tokyo",
    website: "http://kamatamasjid.com",
    city: "Tokyo",
  },
  {
    image: "/images/mosques/otsuka-masjid.jpg",
    mosqueName: "Otsuka Masjid",
    mosqueAddress: "3-42-7 Minami Otsuka, Toshima-ku, Tokyo",
    website: "http://www.islam.or.jp",
    city: "Tokyo",
  },
  {
    image: "/images/mosques/al-tawheed-mosque.jpg",
    mosqueName: "Al-Tawheed Mosque",
    mosqueAddress: "36-6 Hiraoka-cho Hachioji-shi Tokyo",
    website: "http://www.geocities.jp/masjid_al_tawheed/",
    city: "Tokyo",
  },
  {
    image: "/images/mosques/hira-masjid.jpg",
    mosqueName: "Hira Masjid",
    mosqueAddress: "3-3-19 Gyotoku Ekimae, Ichikawa-shi, Chiba",
    website: "http://www.hiramasjid.org/",
    city: "Chiba",
  },
  {
    image: "/images/mosques/jame-masjid-yokohama.jpg",
    mosqueName: "Ja’me Masjid Yokohama",
    mosqueAddress: "1-31-13 Hayabuchi, Tsuzuki-ku, Yokohama-city, Kanagawa",
    website: "http://www.masjid-yokohama.jp",
    city: "Kanagawa",
  },
  {
    image: "/images/mosques/ebina-mosque.jpg",
    mosqueName: "Ebina Mosque",
    mosqueAddress: "3-12-1 Kamigo, Ebina-shi, Kanagawa",
    website: "https://www.facebook.com/pages/Ebina-Mosque/352827714734696",
    city: "Kanagawa",
  },
  {
    image: "/images/mosques/bilal-mosque.jpg",
    mosqueName: "Bilal Mosque",
    mosqueAddress: "1-31-13 Hayabuchi, Tsuzuki-ku, Yokohama-city, Kanagawa",
    website: "http://www.masjid-yokohama.jp",
    city: "Nagano",
  },
  {
    image: "/images/mosques/ebina-mosque.jpg",
    mosqueName: "Ebina Mosque",
    mosqueAddress: "6830-4 Minamijyo, Sakaki-machi, Hanishina-gun, Nagano",
    city: "Nagano",
  },
  {
    image: "/images/mosques/fuji-masjid.jpg",
    mosqueName: "Fuji Masjid",
    mosqueAddress: "2561-29 Nakazato, Fuji-shi, Shizuoka",
    city: "Shizuoka",
  },
  {
    image: "/images/mosques/mohammadi-mosque.jpg",
    mosqueName: "Mohammadi Mosque",
    mosqueAddress: "161-2 Terawaki cho, Minami-ku, Hamamatsu-shi, Shizuoka",
    website: "http://www.hamamatsumosque.com",
    city: "Shizuoka",
  },
  {
    image: "/images/mosques/nagoya-mosque.jpg",
    mosqueName: "Nagoya Mosque",
    mosqueAddress: "2-26-7 Honjindori, Nakamura-ku, Nagoya, Aichi",
    website: "http://en.nagoyamosque.com",
    city: "Aichi",
  },
  {
    image: "/images/mosques/kyoto-masjid.jpg",
    mosqueName: "Kyoto Masjid",
    mosqueAddress: "Miyagaki-cho92, Kamigyo-ku Kawaramachi, Kyoto-shi, Kyotoi",
    website: "http://www.islamjapan.net/ibc/index.html",
    city: "Kyoto",
  },
  {
    image: "/images/mosques/osaka-masjid.jpg",
    mosqueName: "Osaka Masjid",
    mosqueAddress: "4-12-16 Owada Nishi Yodogawa-ku Osaka",
    website: "http://osakamasjid.info",
    city: "Osaka",
  },
  {
    image: "/images/mosques/osaka-ibaraki-mosque.jpg",
    mosqueName: "Osaka Ibaraki Mosque",
    mosqueAddress: "4-6-13, Toyokawa, Ibaraki City , Osaka",
    website: "http://osakamosque.org",
    city: "Osaka",
  },
  {
    image: "/images/mosques/masjid-istiqlal-osaka.jpg",
    mosqueName: "Masjid Istiqlal Osaka",
    mosqueAddress: "1 Chome-2-10 Nakahiraki, Nishinari Ward, Osaka, 557-0022",
    website: "http://www.masjidistiqlalosaka.com/",
    city: "Osaka",
  },
  {
    image: "/images/mosques/japan-dawah-centre.jpg",
    mosqueName: "Japan Da'wah Centre / Masjid Al Dawah",
    mosqueAddress: "3 Chrome-2-10 Yamanouchi, Sumiyoshi Ward, Osaka, 558-0023",
    website: "http://www.japandawah.com/",
    city: "Osaka",
  },
  {
    image: "/images/mosques/kobe-muslim-mosque.jpg",
    mosqueName: "Kobe Muslim Mosque",
    mosqueAddress: "-25-14 Nakayamate Dori, Chuo-ku, Kobe-shi, Hyogo",
    website: "http://kobe-muslim-mosque.com",
    city: "Hyogo",
  },
  {
    image: "/images/mosques/okayama-islamic-center.jpg",
    mosqueName: "Okayama Islamic Center",
    mosqueAddress: "2-1-7 Tsushima Higashi, Kita-ku, Okayama-shi, Okayama",
    website:
      "https://www.facebook.com/pages/Okayama-Islamic-Centre/123860261139298",
    city: "Okayama",
  },
  {
    image: "/images/mosques/higashi-hiroshima-masjid.jpg",
    mosqueName: "Higashi Hiroshima Masjid",
    mosqueAddress:
      "2786-1, Taguchi, Saijyou-cho, Higashihiroshima-shi, Hiroshima",
    website: "https://www.hiroshima-icc.org",
    city: "Hiroshima",
  },
  {
    image: "/images/mosques/niihama-masjid.jpg",
    mosqueName: "Niihama Masjid",
    mosqueAddress: "2-2-43 Ikku-cho, Niihama-shi, Ehime",
    website: "https://www.facebook.com/pages/新居浜マスジド/168623866678430",
    city: "Ehime",
  },
  {
    image: "/images/mosques/fukuoka-masjid.jpg",
    mosqueName: "Fukuoka Masjid",
    mosqueAddress: "3-2-18 Hakozaki , Higashi-ku, Fukuoka-shi, Fukuoka",
    website: "http://www.fukuokamasjid.org",
    city: "Fukuoka",
  },
  {
    image: "/images/mosques/beppu-masjid.jpg",
    mosqueName: "Beppu Masjid",
    mosqueAddress: "10-19 Wakakusa-cho, Beppu-shi, Oita",
    city: "Oita",
  },
  {
    image: "/images/mosques/kumamoto-islamic-center.jpg",
    mosqueName: "Kumamoto Islamic Center",
    mosqueAddress: "5-5-2, Kurokammi, chuou-ku, Kumamoto-shi, Kumamoto",
    website: "http://www.kicjapan.com/",
    city: "Kumamoto",
  },
  {
    image: "/images/mosques/kagoshima-masjid.jpg",
    mosqueName: "Kagoshima Masjid",
    mosqueAddress: "84-21, Masago-cho, Kagoshima",
    website: "http://www.sicc-kagoshima.org",
    city: "Kagoshima",
  },
  {
    image: "/images/mosques/kokinawa-masjid.jpg",
    mosqueName: "Kokinawa Masjid",
    mosqueAddress: "193-78 Uehara, Nishihara-cho, Nakagami-gun, Okinawa",
    city: "Okinawa",
  },
];

export const cities = [
  "Hokkaido",
  "Iwate",
  "Fukushima",
  "Miyagi",
  "Tokyo",
  "Chiba",
  "Kanagawa",
  "Nagano",
  "Shizuoka",
  "Aichi",
  "Kyoto",
  "Osaka",
  "Hyogo",
  "Okayama",
  "Hiroshima",
  "Ehime",
  "Fukuoka",
  "Oita",
  "Kumamoto",
  "Kagoshima",
  "Okinawa",
];
