import React, { Fragment } from "react";
import "./PostsWrapper.styles.css";
import Post from "../Post/Post";
import PostLoader from "../PostLoader/PostLoader";

export default function PostsWrapper({
  posts,
  dataLoaded,
  lastVisible,
  loadMoreData,
  categoryName,
}) {
  return (
    <Fragment>
      {dataLoaded ? (
        <div className="row">
          {posts.length === 0 && (
            <h2 style={{ width: "100%", textAlign: "center" }}>No Posts</h2>
          )}
          {posts.map((post) => {
            return <Post post={post} key={post.id} />;
          })}
          {categoryName == "all" && lastVisible && (
            <div className="load-more-wrapper">
              <div className={!lastVisible ? "load-btn hide" : "load-btn"}>
                <button onClick={loadMoreData} className="load-more">
                  load More
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="row">
          {[1, 2, 3, 4, 5, 6].map((load, id) => (
            <PostLoader />
          ))}
        </div>
      )}
    </Fragment>
  );
}
