import React from "react";
import "./ShahadahPage.styles.css";
import SectionHeader from "../SectionHeader/SectionHeader";

export default function Shahadah() {
  return (
    <div>
      <SectionHeader>
        <h1 className="title">
          真の平安と幸福を見つけるため、ムスリムになる準備は出来ましたか？
        </h1>
      </SectionHeader>
      <section className="light-grey shahadah-section">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6 card-box">
              <p className="big-para">
                ムスリムになるためには、アッラーが唯一の神であること、そして預言者ムハンマドが最後の預言者であることを信じてください
              </p>
            </div>
            <div className="col-md-6">
              <p className="big-text">
                以上の二つを信じることで、あなたはムスリムになる準備が整います。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 vertical-center">
              <p className="big-text">
                おめでとうございます！あなたは人生の正しい道を見つけました！
              </p>
            </div>
            <div className="col-md-6 card-box">
              <p className="big-para">
                ジャパンダアワセンターはあなたに寄り添い、あなたの新しい門出を祝福します！
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="meeting">
        <div className="container">
          <h2 className="heading text-center">
            以下の三つの内から、あなたがムスリムになるために受けたいサービスを選択してください。
          </h2>
          <div className="row">
            <div className="col-md-4">
              <div className="post">
                <img src="/images/post1.jpg" alt="" />
                <div className="post-content">
                  <h3>オンライン入信</h3>
                  <p>
                    こちらは自宅からでもイスラム教に入信できるサービスです。信仰の宣誓を行なったあと、あなたの情報をフォームに入力することで、誰でも簡単かつ迅速にムスリムとなることが出来ます。
                  </p>
                </div>
                <a href="/imperson-shahadah" className="btn invert">
                  オンライン入信を開始
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="post">
                <img src="/images/virtual-shahdah.jpg" alt="" />
                <div className="post-content">
                  <h3>オンライン面談</h3>
                  <p>
                    ムスリムになる前に、相談したい事がある、話しを聞いてほしいけど気軽に赴くことが出来ないと思っておられる方のために、ダアワセンターではオンラインでの面談会をもうけております。
                  </p>
                </div>
                <a
                  href="https://forms.gle/4SxEE9wJE63aJe7L9"
                  className="btn invert"
                  target="_blank"
                  rel="noreferrer"
                >
                  オンライン面談を開始
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="post">
                <img src="/images/shahdah.png" alt="" />
                <div className="post-content">
                  <h3>個別相談など</h3>
                  <p>
                    当センターではムスリムになりたいという方にはいつでも無料で相談会を行なっております。ムスリムになるにあたって不安や疑問などがあるという方は是非一度お越しください。
                  </p>
                </div>
                <a
                  href="https://forms.gle/4SxEE9wJE63aJe7L9"
                  className="btn invert"
                  target="_blank"
                  rel="noreferrer"
                >
                  無料個人相談予約
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
