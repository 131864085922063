import React, { Component, useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { updateDoc, doc, getDoc, arrayRemove } from "firebase/firestore";

export default function Categories() {
  const [categories, setCategories] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const categoryList = doc(db, "categories", "category");
    const getCategory = async () => {
      const fetchedCategory = await getDoc(categoryList);
      if (fetchedCategory.exists()) {
        setCategories(fetchedCategory.data());
      }
    };
    getCategory().then(() => {
      setDataLoaded(true);
    });
  }, []);

  function deleteCategory(e) {
    e.preventDefault();
    const removeArr = e.target[0].value;
    const updateCat = async () => {
      const categoryList = doc(db, "categories", "category");
      await updateDoc(categoryList, {
        category: arrayRemove(removeArr),
      });
    };
    updateCat().then(() => {
      window.location.reload();
    });
  }
  return (
    <div className="category-page">
      {dataLoaded && (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h2 className="heading">Categories</h2>
                {categories &&
                  categories.category.map((category) => (
                    <div className="category-name">
                      <form onSubmit={deleteCategory}>
                        <input type="text" defaultValue={category} disabled />
                        <input type="submit" value="Delete" />
                      </form>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
