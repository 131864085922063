import React from "react";
import styles from "./VideoModal.module.css";
export default function VideoModal({ setShowVideoModal }) {
  return (
    <div
      className={styles.modal_wrapper}
      onClick={() => {
        setShowVideoModal(false);
      }}
    >
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.video}>
          <iframe
            className="home-emd"
            width="100%"
            src="https://www.youtube.com/embed/6ac2buAJkcg"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            poster="/images/mission.jpg"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
