import React, { useContext } from "react";
import MainMenuItems from "../MainMenuItems/MainMenuItems";
import "./Sidebar.styles.css";
import { auth } from "../../firebase-config";
import { AuthContext } from "../../Context/Auth";
import { Link } from "react-router-dom";
export default function Sidebar({ toggleMenu, openMenu }) {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="sidebar">
      <div className={`${openMenu ? "open-menu" : ""} sidebar-menu`}>
        <div className="close-button" onClick={toggleMenu}>
          <i className="fas fa-times"></i>
        </div>
        <ul>
          <div className="">
            <ul>
              {currentUser && (
                <li>
                  <Link to="/admin" onClick={toggleMenu}>
                    Admin Dashboard
                  </Link>
                </li>
              )}
              <MainMenuItems closeSidebar={toggleMenu} />
              <li>
                <Link to="/posts" onClick={toggleMenu}>
                  ブログ
                </Link>
              </li>
              <li>
                <Link to="/events" onClick={toggleMenu}>
                  イベント
                </Link>
              </li>
              <li>
                <a
                  href="https://www.nippon-muslim.co.jp/"
                  onClick={toggleMenu}
                  target="_blank"
                  rel="noreferrer"
                >
                  日本ムスリム株式会社
                </a>
              </li>
              <li>
                <a
                  href="https://forms.gle/6e9WEmzCvRdmBGAf9"
                  target="_blank"
                  rel="noreferrer"
                  onClick={toggleMenu}
                >
                  お問合せ
                </a>
              </li>
              <li onClick={toggleMenu}>
                {!currentUser ? (
                  <>
                    <Link to="/login">運営者ログイン</Link>
                  </>
                ) : (
                  <>
                    <button
                      className="menu-logout"
                      onClick={() => auth.signOut()}
                    >
                      Logout
                    </button>
                  </>
                )}
              </li>
            </ul>
          </div>
        </ul>
      </div>
      {/* <div className="social-icons">
        <a
          href="https://www.facebook.com/JapanDawahCentreMedia/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/japan.dawah.centre.v1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
      </div> */}
    </div>
  );
}
