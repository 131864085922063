import React from "react";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

export default function JapanDawahCenter() {
  return (
    <div>
      <SectionHeader>
        <h1 className="title">ジャパンダアワセンター</h1>
        <h2 className="pre-heading">Japan Da’wah Centre</h2>
      </SectionHeader>

      <section className="intro light-grey">
        <div className="container">
          <h2 className="heading">ジャパンダアワセンターについて</h2>
          <p>
            日本人とイスラームの情報を共有し、マレーシアの文化やイスラームに関する情報を交換する場所です。イスラームは世界、特に日本で最も誤解されている宗教です。私たちの使命は、できるだけ多くの人々にイスラームに関する正しい情報を伝えることです。
            イスラームはテロとは関係がなく、イスラームは平和と調和の両方が存在することを人々に伝えたいと思います。
            私たちのスローガンは、多文化コミュニティの一体化に焦点を当てた「多様性と調和」です。"
          </p>
        </div>
      </section>

      <section className="cards">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h4>事業、活動内容</h4>
              <p className="text">Enterprise、Activity Details</p>
              <p className="japanese-text">
                ジャパンダアワセンターは、建物（場所）の名前です。
                活動は、一般社団法人ジャパンムスリムファウンデーションの下で行っています。
                こちらの代表理事はゾルカナインです。
                ダアワセンターは、ボランティアでのイスラムの紹介がメインです。
              </p>
            </div>
            <div className="col-md-4">
              <h4>活動内容</h4>
              <p className="text">Activity Details</p>
              <div className="list">
                <ul className="list-items">
                  <li>イスラムの勉強会</li>
                  <li>国際交流センターでのセミナー</li>
                  <li>大学、高校でのイスラムの講演など</li>
                  <li>
                    イベントの企画運営 例）2018,2019年　ムスリム忘年会
                    モスクでのイフタール体験（ラマダーン月の断食明けの食事会）など
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <h4>日本ムスリム株式会社との関係性</h4>
              <p className="text">Relation with Nippon Muslim Corporation</p>
              <p className="japanese-text">
                会社はビジネス、ファウンデーションは、ボランティアです。
                日本ムスリム株式会社の利益の一部は、ファウンデーションの運営資金になります。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bottom light-grey">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-6">
              {/* <h4>建物概要</h4>
                            <p className="text">Outline</p> */}
              <img
                src="/images/image-012.jpg"
                alt="japan-dawah-center-building"
              />
            </div>
            <div className="col-md-6">
              <div className="content">
                <h2 className="heading">ジャパンダアワセンター</h2>
                <p>
                  所在地　〒558-0023
                  大阪府大阪市住吉区山之内3丁目2-10　ジャパンダアワセンター 4F
                </p>
                <p> 電話番号　06-6629-8210</p>
                <p>携帯電話（代表直通）090-6051-1369</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
