import React, { Fragment, useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { doc, getDoc } from "@firebase/firestore";

import Filters from "../Filters/Filters";
export default function CategoryScroll({ loadPostData, cat }) {
  const [categoriesList, setCategoriesList] = useState("");
  const [showAccordion, setShowAccordion] = useState(true);
  const [closeAccordion, setCloseAccordion] = useState(false);
  useEffect(() => {
    const categoryList = doc(db, "categories", "category");
    const getCategory = async () => {
      const fetchedCategory = await getDoc(categoryList);
      if (fetchedCategory.exists()) {
        setCategoriesList(fetchedCategory.data());
      }
    };
    getCategory();
  }, []);
  function handleCategoryChange(category) {
    loadPostData(category);
  }

  return (
    <Filters
      title={"Categories"}
      current={cat}
      handleTabChange={handleCategoryChange}
      arr={categoriesList.category}
    />
  );
}
